<template>
  <el-container>
    <el-dialog title="解决" :visible.sync="equipmentSolve" width="30%" @close="close">
      <div class="content">
        <div class="input_item">
          <div class="item_vlaue">处理记录</div>
          <div class="item_key">
            <el-input type="textarea" :rows="4" v-model="form.processingRecord" placeholder="请输入内容"></el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="equipmentSolve = false">取 消</el-button>
        <el-button type="primary" @click="getWarnReportInfo()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
export default {
  props: ['equipmentSolve', 'getSolve'],
  data() {
    return {
      form: {
        //解除警报
        processingRecord: '',
        processingStatus: '1',
        //解决方
        resolver: '社区解决'
      }
    }
  },
  methods: {
    close() {
      this.$emit('closeEquipmentSolve')
    },
    async getWarnReportInfo() {
      this.getSolve(this.form)
      this.equipmentSolve = false
    }
  }
}
</script>
<style lang="less" scoped>
.item_vlaue {
  padding-bottom: 16px;
}
.item_key {
  display: flex;
  align-items: center;
  .name {
    margin-right: 16px;
  }
  .radio {
    display: flex;
  }
}
</style>
