import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import create from './utils/create'
import axios from 'axios';

import createCall from './components/common/callPhone/index'
import createCallDt from './components/common/callPhone/callWarn.js'
import createDlgSolve from './components/common/callPhone/warnSolve.js'
import { getPhoneInfo } from './api/call'
import './plugins/element.js'
import './assets/css/public.css'
import './assets/css/theme/index.css'
import { formatDate, fmtTime, mobileStr, userNameStr, userCardNo } from '@/utils/index.js'
import './components/globalCall/js/jquery.min.js'
import './components/globalCall/index'
import dataV from '@jiaminghi/data-view'

Vue.config.productionTip = false
Vue.prototype.formatDate = formatDate
Vue.prototype.fmtTime = fmtTime
Vue.prototype.$create = create
Vue.prototype.$createCall = createCall
Vue.prototype.fmtTime = fmtTime
Vue.prototype.mobileStr = mobileStr // 手机号中间4位加*
Vue.prototype.userNameStr = userNameStr // 姓名加密
Vue.prototype.userCardNo = userCardNo // 身份证号加密
Vue.prototype.cpRecord = {}
Vue.prototype.$axios = axios
Vue.use(dataV)

window.cp = {
  createCall,
  createCallDt,
  createDlgSolve,
  getPhoneInfo, //通过手机号获取用户信息
  cpRecord: {},
}
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
}).$mount('#app')
