<template>
  <el-container>
    <el-header class="headerBox">
      <div class="title">{{ userInfo.propertyName }}{{ userInfo.villageName }}养老监控中心</div>
      <div class="rightBox">
        <div class="userBox">
          <div class="ImgBox">
            <img src="../assets/hk_logo_w.svg" alt="" />
          </div>
          <div>{{ userInfo.name }} -</div>
          <div>{{ userInfo.roleName }}</div>
        </div>
        <div class="logout" @click="logout()"><i class="el-icon-delete"></i>退出</div>
      </div>
    </el-header>
    <el-container>
      <el-aside width="200px">
        <el-menu
          default-active="1"
          router
          class="menuBox"
          @open="handleOpen"
          @close="handleClose"
          background-color="#e4e8e8"
          text-color="#202626"
          active-text-color="#09BEBE"
        >
          <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>业主档案</span>
            </template>
            <!-- <el-menu-item index="1-1" route="addArchives">新增接入</el-menu-item> -->
            <el-menu-item index="1-1" route="fileList">业主档案</el-menu-item>
            <el-menu-item index="1-2" route="confirmed">待确认接入</el-menu-item>
          </el-submenu>
          <el-submenu index="2" v-if="userInfo.roleName == '超级管理员'">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>社区管理</span>
            </template>
            <el-menu-item index="2-1" route="residentialQuarters">社区管理</el-menu-item>
            <el-menu-item index="2-2" route="house">房屋管理</el-menu-item>
            <el-menu-item index="2-3" route="member">成员管理</el-menu-item>
            <!-- <el-menu-item index="2-4" route="beOnDuty">值班管理</el-menu-item> -->
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>预警管理</span>
            </template>
            <el-menu-item index="3-1" route="call">来电预警</el-menu-item>
            <el-menu-item index="3-2" route="equipment">设备预警</el-menu-item>
          </el-submenu>
          <el-submenu index="4">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>话务记录</span>
            </template>
            <el-menu-item index="4-1" route="incoming">呼入记录</el-menu-item>
            <el-menu-item index="4-2" route="exhale">呼出记录</el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-main class="mainBox">
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
// 从session中获取token信息
import session from '@/utils/session.js'
import { loginOutToTime } from '@/api/login.js'
export default {
  data() {
    return {
      userInfo: {},
      query: {
        page: 1,
        pageSize: 10
      }
    }
  },
  mounted() {
    this.userInfo = session.getUserInfo()
  },
  methods: {
    async logout() {
      let result = await loginOutToTime(this.query, this.userInfo.id)
      if (result.success) {
        sessionStorage.removeItem('session')
        sessionStorage.removeItem('userInfo')
        this.$router.push({ path: '/' })
      }
    },
    handleOpen(key, keyPath) {
      // console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath)
    }
  }
}
</script>
<style lang="less" scoped>
.NavBox {
  position: fixed;
}
.logout {
  cursor: pointer;
}
.headerBox {
  background: #09bebe;
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100vw;
  z-index: 999;
  .title {
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    color: #ffffff;
    line-height: 60px;
  }
  .rightBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
    .userBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 40px;
      .ImgBox {
        width: 24px;
        height: 24px;
        margin-right: 12px;
      }
    }
  }
}
.menuBox {
  height: calc(100vh - 60px);
  position: fixed;
  width: 200px;
  z-index: 999;
  margin-top: 60px;
}
.mainBox {
  // margin-top: 60px;
  margin: 60px 0 16px 0;
}
</style>
