<template>
  <el-container class="mainBox">
    <div class="title">你好，欢迎注册社区养老服务管理系统</div>
    <div>为保证更好的为您服务，请认真填写一下信息</div>
    <br />
    <br />
    <br />
    <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-row>
        <el-col :span="12">
          <el-form-item label="登陆账号" prop="accountId">
            <el-input v-model="ruleForm.accountId"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="登陆密码" prop="password">
            <el-input v-model="ruleForm.password" type="password"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="社区名称" prop="propertyName">
            <el-input v-model="ruleForm.propertyName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="社区电话" prop="propertyPhone">
            <el-input v-model="ruleForm.propertyPhone"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所属地区" prop="propertyArea">
            <el-cascader
              ref="cascader"
              v-model="ruleForm.city"
              :options="AdsOptions"
              :props="proCityAreaTreeProps"
              @change="handleChange"
              filterable
              style="width: 100%"
            ></el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="详细地址" prop="propertyAddress">
            <el-input v-model="ruleForm.propertyAddress"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="负责人姓名" prop="contactPerson">
            <el-input v-model="ruleForm.contactPerson"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="负责人电话" prop="contactPhone">
            <el-input v-model="ruleForm.contactPhone"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="小区数量" prop="generalBuilding">
            <el-input v-model="ruleForm.generalBuilding">
              <template slot="append">个</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="覆盖总户数" prop="totalHouseholds">
            <el-input v-model="ruleForm.totalHouseholds">
              <template slot="append">户</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="占地面积" prop="areaCovered">
            <el-input v-model="ruleForm.areaCovered">
              <template slot="append">㎡</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="备注" prop="remarks">
            <el-input v-model="ruleForm.remarks"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-form-item label="行政用房" prop="adminHousing">
          <el-checkbox-group v-model="ruleForm.adminHousing">
            <el-checkbox label="办公室"></el-checkbox>
            <el-checkbox label="门卫室"></el-checkbox>
            <el-checkbox label="监控室"></el-checkbox>
            <el-checkbox label="会议室"></el-checkbox>
            <el-checkbox label="培训室"></el-checkbox>
            <el-checkbox label="接待室"></el-checkbox>
            <el-checkbox label="库房"></el-checkbox>
            <el-checkbox label="其它"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-row>
    </el-form>
    <el-col :span="24">
      <div class="from_item">
        <el-button type="primary" style="width: 100%" @click="acRegister">注册</el-button>
      </div>
      <div class="from_item">
        <div class="switch">已有账号，<span class="lgoin" @click="openLogin">去登录</span></div>
      </div>
    </el-col>
  </el-container>
</template>
<script>
import { accountRegister, fileUpload } from '@/api/account.js'
import { proCityAreaFindAll } from '@/api/public.js'
export default {
  data() {
    return {
      ruleForm: {
        type: 1, // 社区
        accountId: '',
        adminHousing: [],
        areaCovered: '',
        contactPerson: '',
        contactPhone: '',
        generalBuilding: '',
        password: '',
        propertyAddress: '',
        propertyPhone: '',
        remarks: '',
        totalHouseholds: '',
        propertyProvince: '',
        propertyCity: '',
        propertyArea: '',
        propertyName: '',
        villageName: '社区'
      },
      AdsOptions: [],
      proCityAreaTreeProps: {
        emitPath: false,
        value: 'code',
        label: 'name',
        children: 'children'
      }
    }
  },
  mounted() {
    this.getProCityAreaFindAll()
  },
  methods: {
    async getProCityAreaFindAll() {
      let res = await proCityAreaFindAll()
      this.AdsOptions = res.data
    },
    handleChange(value) {
      let nodesObj = this.$refs['cascader'].getCheckedNodes()
      this.ruleForm.propertyProvince = nodesObj[0].path[0]
      this.ruleForm.propertyCity = nodesObj[0].path[1]
      this.ruleForm.propertyArea = nodesObj[0].path[2]
    },
    openLogin() {
      this.$router.push({ path: '/login' })
    },
    openRegister() {
      this.$router.push({ path: '/index' })
    },
    async acRegister() {
      const params = {
        ...this.ruleForm,
        adminHousing: this.ruleForm.adminHousing.toString()
      }
      let result = await accountRegister(params)
      if (result.success) {
        let that = this
        this.$message.success('注册成功')
        setTimeout(() => {
          that.$router.push({ path: '/login' })
        }, 2000)
      } else {
        this.$message.error(result.message)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.mainBox {
  margin: auto;
  width: 1000px;
  display: block;
  padding: 60px 0;
}
.title {
  font-size: 40px;
  font-weight: 500;
}
.btn {
  width: 100%;
  margin-top: 24px;
}
.lgoin {
  cursor: pointer;
}
.switch {
  padding-top: 24px;
  display: flex;
  justify-content: center;
  span {
    color: #09bebe;
    font-weight: 500;
  }
}
.from {
  padding-top: 40px;
  .from_item {
    padding: 12px 0;
    .from_item_vlaue {
      padding-bottom: 16px;
    }
    .from_item_key {
      display: flex;
      align-items: center;
    }
    .address {
      width: 100%;
      height: 40px;
    }
    .year {
      width: 100%;
    }
  }
}
</style>
