<template>
  <el-container>
    <el-dialog
      title="设备报修"
      v-el-drag-dialog
      :visible.sync="warnReport"
      width="20%"
      @close="close"
      :modal="false"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="content">
        <div class="input_item" :model="reportForm" ref="reportForm">
          <div class="item_vlaue">报修内容</div>
          <div class="item_key">
            <div class="item_key">
              <el-input type="textarea" :rows="6" placeholder="请输入简单描述报修内容" v-model="reportForm.reason">
              </el-input>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="warnReport = false">取 消</el-button>
        <el-button type="primary" @click="getWarnReportInfo">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
// 自定义拖拽
import elDragDialog from '@/directive/el-dragDialog'
export default {
  props: ['warnReport', 'warnReportInfo'],
  directives: {
    elDragDialog
  },
  data() {
    return {
      reportForm: {
        //报修内容
        reason: '',
        repair: '1',
        //解决方
        resolver: '社区解决'
      }
    }
  },
  methods: {
    close() {
      this.$emit('closeWarnReport')
    },
    async getWarnReportInfo() {
      this.warnReportInfo(this.reportForm)
      this.warnReport = false
    }
  }
}
</script>
<style lang="less" scoped>
.input_item {
  padding: 8px 0;
  .item_vlaue {
    padding-bottom: 8px;
  }
  .select {
    width: 100%;
  }
}
</style>
