<template>
  <el-container>
    <el-header class="header">
      <div class="leftTit">社区管理</div>
      <div class="rightBtn">
        <!-- <el-button type="primary" @click="viewContract">查看合同</el-button> -->
        <!-- <el-button type="danger" @click="OpenRescind">解约</el-button> -->
      </div>
    </el-header>
    <el-main>
      <!-- 基本信息 -->
      <div class="baseInfo">
        <div class="baseInfo_tit">
          <div>基本信息</div>
          <div>
            <el-button size="small" type="primary" @click="OpenEditProperty">编辑</el-button>
          </div>
        </div>
        <el-row class="baseInfo_group">
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">社区名称：</div>
              <div class="grid_key">{{ propertyInfo.propertyName }}{{ propertyInfo.villageName }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">社区电话：</div>
              <div class="grid_key">{{ propertyInfo.propertyPhone }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">负责人姓名：</div>
              <div class="grid_key">{{ propertyInfo.contactPerson }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">负责人电话：</div>
              <div class="grid_key">{{ propertyInfo.contactPhone }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">所属地区：</div>
              <div class="grid_key">
                {{ propertyInfo.propertyProvince }}{{ propertyInfo.propertyCity }}{{ propertyInfo.propertyArea }}
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">详细地址：</div>
              <div class="grid_key">{{ propertyInfo.propertyAddress }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">占地面积：</div>
              <div class="grid_key">{{ propertyInfo.areaCovered }}㎡</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">总小区数：</div>
              <div class="grid_key">{{ propertyInfo.generalBuilding }}个</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">总户数：</div>
              <div class="grid_key">{{ propertyInfo.totalHouseholds }}户</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">行政用房：</div>
              <div class="grid_key">{{ adminHousing }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">备注：</div>
              <div class="grid_key">{{ propertyInfo.remarks }}</div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 合作信息 -->
      <div class="cooperationInfo">
        <div class="cooperationInfo_tit">合作信息</div>
        <el-row class="cooperationInfo_group">
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">合同编号：</div>
              <div class="grid_key">{{ contractInfo.contractNo }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">合同期限：</div>
              <div class="grid_key">{{ contractInfo.term }}年</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">起始日期：</div>
              <div class="grid_key">{{ contractInfo.startTime }} - {{ contractInfo.endTime }}</div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 打开编辑社区弹框 -->
      <editProperty
        :updateInfo="updateInfo"
        :ppInfo="propertyInfo"
        :editProperty="editPropertyShow"
        @closeEditProperty="showEditProperty()"
      ></editProperty>
      <!-- 打开查看合同弹框 -->
      <lookContract
        :pdfSrc="pdfSrc"
        :lookContract="lookContractShow"
        @closeLookContract="showLookContract()"
      ></lookContract>
    </el-main>
  </el-container>
</template>
<script>
import EditProperty from '@/components/property/editProperty'
import LookContract from '@/components/property/lookContract'
import Rescind from '@/components/property/rescind'
import { getAreaInfo, updateAreaInfo, getContractFile } from '@/api/property.js'
import { getProvinceCode, getCityCode, getAreaCode } from '@/api/public.js'

import session from '@/utils/session.js'

export default {
  props: ['callSolve', 'lookContract'],
  components: {
    editProperty: EditProperty,
    lookContract: LookContract,
    rescind: Rescind
  },
  data() {
    return {
      editPropertyShow: false,
      lookContractShow: false,
      businessLicenseShow: false,
      userInfo: {},
      propertyInfo: {},
      adminHousing: '',
      pdfSrc: '',
      contractInfo: {}
    }
  },
  mounted() {
    this.userInfo = session.getUserInfo()
    this.getInfo()
    this.getPropertyContract()
  },
  methods: {
    //获取小区信息
    async getInfo() {
      let result = await getAreaInfo(this.userInfo.propertyId)
      if (result.success) {
        if (result.success) {
          this.propertyInfo = result.data
          this.adminHousing = this.propertyInfo.adminHousing.toString()
          // 省份
          let res = await getProvinceCode(this.propertyInfo.propertyProvince)
          if (res.success) {
            this.propertyInfo.propertyProvince = res.data.name
          }
          // 城市
          let ress = await getCityCode(this.propertyInfo.propertyCity)
          if (ress.success) {
            this.propertyInfo.propertyCity = ress.data.name
          }
          // 地区
          let resss = await getAreaCode(this.propertyInfo.propertyArea)
          if (resss.success) {
            this.propertyInfo.propertyArea = resss.data.name
          }
        }
      }
    },
    //更新信息
    async updateInfo(data) {
      let result = await updateAreaInfo(this.userInfo.propertyId, data)
      if (result.success) {
        if (result.success) {
          this.$message.success('更新成功')
          this.getInfo()
        }
      }
    },
    //获取社区合作信息
    async getPropertyContract() {
      let result = await getContractFile(this.userInfo.propertyId)
      if (result.success) {
        if (result.success) {
          this.contractInfo = result.data
        }
      }
    },
    //查看合同
    async viewContract() {
      let result = await getContractFile(this.userInfo.propertyId)
      if (result.success) {
        if (result.success) {
          this.pdfSrc = result.data.contractImg
          this.openContract()
        }
      }
    },
    showEditProperty() {
      this.editPropertyShow = false
    },
    OpenEditProperty() {
      this.editPropertyShow = true
    },
    showLookContract() {
      this.lookContractShow = false
    },
    openContract() {
      this.lookContractShow = true
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
}
.baseInfo {
  margin: 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .baseInfo_tit {
    font-weight: 500;
    padding-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .baseInfo_group {
    .grid-content {
      padding: 8px 0;
    }
  }
  .grid-content {
    display: flex;
  }
}
.cooperationInfo {
  margin: 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .cooperationInfo_tit {
    font-weight: 500;
    padding-bottom: 16px;
  }
  .cooperationInfo_group {
    padding: 8px 0;
  }
  .grid-content {
    display: flex;
  }
}
</style>
