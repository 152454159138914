<template>
  <el-container>
    <el-dialog title="编辑社区信息" :visible.sync="editProperty" width="60%" @close="close">
      <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="社区名称" prop="name">
              <el-input v-model="ruleForm.propertyName"></el-input>
            </el-form-item>
            <el-form-item label="所属地区" prop="propertyArea">
              <el-cascader
                ref="cascader"
                v-model="ruleForm.city"
                :options="AdsOptions"
                :props="proCityAreaTreeProps"
                @change="handleChange"
                filterable
                style="width: 100%"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="负责人姓名" prop="name">
              <el-input v-model="ruleForm.contactPerson"></el-input>
            </el-form-item>
            <el-form-item label="总栋楼" prop="name">
              <el-input v-model="ruleForm.generalBuilding"></el-input>
            </el-form-item>
            <el-form-item label="占地面积" prop="name">
              <el-input v-model="ruleForm.areaCovered"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="社区电话" prop="name">
              <el-input v-model="ruleForm.propertyPhone"></el-input>
            </el-form-item>
            <el-form-item label="详细地址" prop="name">
              <el-input v-model="ruleForm.propertyAddress"></el-input>
            </el-form-item>
            <el-form-item label="负责人电话" prop="name">
              <el-input v-model="ruleForm.contactPhone"></el-input>
            </el-form-item>
            <el-form-item label="覆盖总户数" prop="name">
              <el-input v-model="ruleForm.totalHouseholds"></el-input>
            </el-form-item>
            <el-form-item label="备注" prop="name">
              <el-input v-model="ruleForm.remarks"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="行政用房" prop="adminHousing">
            <el-checkbox-group v-model="ruleForm.adminHousing">
              <el-checkbox label="办公室"></el-checkbox>
              <el-checkbox label="门卫室"></el-checkbox>
              <el-checkbox label="监控室"></el-checkbox>
              <el-checkbox label="会议室"></el-checkbox>
              <el-checkbox label="培训室"></el-checkbox>
              <el-checkbox label="接待室"></el-checkbox>
              <el-checkbox label="库房"></el-checkbox>
              <el-checkbox label="其它"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="ok">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
import { proCityAreaFindAll } from '@/api/public.js'
export default {
  props: ['editProperty', 'ppInfo', 'updateInfo'],
  data() {
    return {
      ruleForm: {
        accountId: '',
        adminHousing: [],
        areaCovered: '',
        contactPerson: '',
        contactPhone: '',
        generalBuilding: '',
        password: '',
        propertyAddress: '',
        propertyPhone: '',
        remarks: '',
        totalHouseholds: '',
        propertyProvince: '',
        propertyCity: '',
        propertyArea: '',
        villageName: '社区'
      },
      AdsOptions: [],
      proCityAreaTreeProps: {
        emitPath: false,
        value: 'code',
        label: 'name',
        children: 'children'
      }
    }
  },
  watch: {
    ppInfo(n, o) {
      this.ruleForm = n
      this.ruleForm.adminHousing = n.adminHousing.split(',')
    }
  },
  mounted() {
    this.getProCityAreaFindAll()
  },
  methods: {
    async getProCityAreaFindAll() {
      let res = await proCityAreaFindAll()
      this.AdsOptions = res.data
    },
    handleChange(value) {
      let nodesObj = this.$refs['cascader'].getCheckedNodes()
      this.ruleForm.propertyProvince = nodesObj[0].path[0]
      this.ruleForm.propertyCity = nodesObj[0].path[1]
      this.ruleForm.propertyArea = nodesObj[0].path[2]
    },
    close() {
      this.$emit('closeEditProperty')
    },
    ok() {
      this.ruleForm.adminHousing = this.ruleForm.adminHousing.toString()
      this.updateInfo(this.ruleForm)
      this.close()
    }
  }
}
</script>
<style lang="less" scoped>
.input_item {
  padding: 8px 0;
  .item_vlaue {
    padding-bottom: 8px;
  }
  .address {
    width: 100%;
    height: 40px;
  }
  .year {
    width: 100%;
  }
  .el-checkbox {
    padding: 8px 0;
  }
}
</style>
