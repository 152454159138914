<template>
  <el-container>
    <el-dialog
      title="新增接入"
      v-el-drag-dialog
      :visible.sync="addUser"
      width="40%"
      @close="close"
      :modal="false"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="content" :model="userForm" ref="userForm">
        <div class="input_item">
          <div class="item_vlaue">选择房号</div>
          <div class="item_key">
            <el-cascader ref="cascader" v-model="userForm.roomId" :options="roomList"></el-cascader>
          </div>
        </div>
        <div class="input_item">
          <div class="item_vlaue">姓名</div>
          <div class="item_key">
            <el-input v-model="userForm.ownerName" placeholder="请输入姓名"></el-input>
          </div>
        </div>
        <div class="input_item">
          <div class="item_vlaue">电话</div>
          <div class="item_key">
            <el-input v-model="userForm.ownerPhone" placeholder="请输入电话"></el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clossAddUser">取 消</el-button>
        <el-button type="primary" @click="getPay()">提交申请</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
// 自定义拖拽
import elDragDialog from '@/directive/el-dragDialog'
// 社区接口
import { getpropertyRoomList } from '@/api/property.js'
// 居民接口
import { queryNewProcess } from '@/api/archives.js'
export default {
  props: ['addUser', 'addUserInfo'],
  directives: {
    elDragDialog
  },
  data() {
    return {
      userForm: {
        ownerName: '',
        ownerPhone: '',
        roomId: '',
        serviceTypeId: 1
      },
      value: [],
      roomList: []
    }
  },
  mounted() {
    this.getPropertyRoomList()
  },
  methods: {
    // 关闭弹框
    clossAddUser() {
      this.addUser = false
      this.userForm.ownerName = ''
      this.userForm.ownerPhone = ''
      this.userForm.roomId = ''
      this.userForm.propertyAgentPay = 0
    },
    // 获取物业所有房屋
    async getPropertyRoomList() {
      let result = await getpropertyRoomList()
      let floors = Object.values(result.data.floors) //对象转化为数组
      if (result.success) {
        this.roomList = this.getDt(floors)
      }
    },
    //递归获取信息
    getDt(list) {
      let tree = []
      const that = this
      list.map((d) => {
        tree.push({
          value: d.room_name ? d.id : d.floorName ? d.id : d.unitName ? d.id : d.layerName ? d.id : '',
          label: d.room_name
            ? d.room_name + '室'
            : d.floorName
            ? d.floorName + '号楼'
            : d.unitName
            ? d.unitName + '单元'
            : d.layerName
            ? d.layerName + '层'
            : '',
          children: d.children ? that.getDt(d.children) : null
        })
      })
      return tree
    },
    close() {
      this.$emit('closeAddUser')
    },
    async getPay() {
      this.userForm.roomId = this.userForm.roomId[3]
      let res = await queryNewProcess(this.userForm)
      if (res.success) {
        this.$message.success('申请成功！')
      } else {
        this.$message.success('请求超时请稍后重试')
      }
    }
  }
}
</script>
<style lang="less" scoped>
.input_item {
  padding: 8px 0;
  .item_vlaue {
    padding-bottom: 8px;
  }
  .select {
    width: 100%;
  }
}
.el-cascader {
  width: 100%;
}
.depositBox {
  margin-top: 8px;
  .deposit_content {
    padding: 16px;
    background: #09bebe;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    .d_l_tit {
      font-size: 18px;
      font-weight: bold;
    }
    .d_l_dec {
      font-size: 14px;
    }
  }
  p {
    font-size: 12px;
  }
}
</style>
