<template>
  <div class="pageBox" :style="pageBox">
    <div class="pageInner">
      <div class="pageImgBg">
        <!-- 头部 -->
        <div class="headeBox">
          <div class="h_inner mainTopBox">
            <div class="h_i_leftBox">
              <div class="h_i_l_map">
                <div class="h_i_l_m_icon">
                  <img src="../../assets/img/map_icon.svg" />
                </div>
                <div class="h_i_l_m_dec">{{ propertyInfo.propertyCity }}</div>
              </div>
              <div>20°C 多云转晴 ｜ PM2.5：1度 负氧离子含量：356.82/</div>
            </div>
            <div class="h_i_minBox">
              <div class="h_i_m_tit">{{ propertyInfo.propertyName }}{{ propertyInfo.villageName }}养老监控中心</div>
            </div>
            <div class="h_i_rightBox">
              <div class="hir_left">
                <div style="padding-right: 16px">{{ dateFormat(date) }}</div>
                <div class="h_i_l_map">
                  <i class="el-icon-user-solid" style="font-size: 20px; padding-right: 8px"></i>
                  <div class="h_i_l_m_dec">{{ userInfo.name }}</div>
                </div>
              </div>
              <div>
                <el-button size="mini" @click="enterFullscreen" v-if="fullscreen == false">全屏</el-button>
                <el-button size="mini" @click="exitFullscreen" v-if="fullscreen == true">取消全屏</el-button>
                <el-button size="mini" @click="play()">音频</el-button>
                <el-button size="mini" onclick="javascript:location.reload()">刷新</el-button>
                <el-button type="danger" size="mini" @click="logout()">退出登录</el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="headeShow"></div>
        <!-- 中间内容 -->
        <div class="mainBox">
          <!-- 左侧 -->
          <div class="mainBox_gird mainLeftBox">
            <div class="mainLeftBox">
              <!-- 基础信息 -->
              <transition name="baseBox">
                <div class="m_gird m_baseBox" v-if="baseBox == true">
                  <!-- 标题 -->
                  <div class="m_g_heade">
                    <div class="m_h_inner">
                      <span>{{ propertyInfo.propertyName }} - </span>
                      <span>{{ propertyInfo.villageName }}</span>
                    </div>
                  </div>
                  <!-- 内容 -->
                  <el-row class="m_content">
                    <el-col :span="12">
                      <div class="m_c_grid">
                        <div class="m_c_g_icon">
                          <img src="../../assets/img/floor_icon.svg" />
                        </div>
                        <div class="m_c_g_txt">
                          <div class="m_c_g_t_number">{{ propertyInfo.generalBuilding }}</div>
                          <div class="m_c_g_t_tit">小区数量(个)</div>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="m_c_grid">
                        <div class="m_c_g_icon">
                          <img src="../../assets/img/room_icon.svg" />
                        </div>
                        <div class="m_c_g_txt">
                          <div class="m_c_g_t_number">{{ propertyInfo.totalHouseholds }}</div>
                          <div class="m_c_g_t_tit">总户数(户)</div>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="m_c_grid">
                        <div class="m_c_g_icon">
                          <img src="../../assets/img/access_icon.svg" />
                        </div>
                        <div class="m_c_g_txt">
                          <div class="m_c_g_t_number">
                            {{ userProfileSize }}
                          </div>
                          <div class="m_c_g_t_tit">总接入(户)</div>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="m_c_grid">
                        <div class="m_c_g_icon">
                          <img src="../../assets/img/server_icon.svg" />
                        </div>
                        <div class="m_c_g_txt">
                          <div class="m_c_g_t_number">{{ severFamilySize }}</div>
                          <div class="m_c_g_t_tit">服务人数(人)</div>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="m_c_grid">
                        <div class="m_c_g_icon">
                          <img src="../../assets/img/addServer_icon.svg" />
                        </div>
                        <div class="m_c_g_txt">
                          <div class="m_c_g_t_number">
                            {{ parseInt(this.userReviewThisMonthTotal) + parseInt(this.userProfileThisMonthTotal) }}
                          </div>
                          <div class="m_c_g_t_tit">本月新增(户)</div>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="m_c_grid">
                        <div class="m_c_g_icon">
                          <img src="../../assets/img/proportion.svg" />
                        </div>
                        <div class="m_c_g_txt">
                          <div class="m_c_g_t_number">{{ accessRate }}</div>
                          <div class="m_c_g_t_tit">总接入率(%)</div>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </transition>
              <!-- 老人分布 -->
              <transition name="dataBox">
                <div class="m_gird m_oldBox" v-if="dataBox == true">
                  <!-- 标题 -->
                  <div class="m_g_heade">
                    <div class="m_h_inner">
                      <span>老人分布情况</span>
                    </div>
                  </div>
                  <!-- 内容 -->
                  <div class="m_content mgc_old">
                    <dv-active-ring-chart :config="oldData" style="width: 130px; height: 130px" />
                    <div class="mgco_list">
                      <div class="mgcol_items">
                        <span>总数</span>
                        <span>{{ severFamilySize }}</span>
                      </div>
                      <div class="mgcol_items" v-for="(item, index) in oldData.data" :key="index">
                        <span>{{ item.name }}</span>
                        <span>{{ item.value }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
              <!-- 设备 -->
              <transition name="dataBox">
                <div class="m_gird m_dataBox" v-if="dataBox == true">
                  <!-- 标题 -->
                  <div class="m_g_heade">
                    <div class="m_h_inner">
                      <span>设备信息</span>
                    </div>
                  </div>
                  <!-- 内容 -->
                  <div class="m_content mgc_old">
                    <dv-active-ring-chart :config="devicePhoneData" style="width: 130px; height: 130px" />
                    <div class="mgco_list">
                      <div class="mgcol_items">
                        <span>总数</span>
                        <span>{{ propertySmartPhoneTotal }}</span>
                      </div>
                      <div class="mgcol_items" v-for="(item, index) in devicePhoneData.data" :key="index">
                        <span>{{ item.name }}</span>
                        <span>{{ item.value }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="m_content mgc_old" style="padding-top: 0">
                    <dv-active-ring-chart :config="deviceData" style="width: 130px; height: 130px" />
                    <div class="mgco_list">
                      <div class="mgcol_items">
                        <span>总数</span>
                        <span>{{ propertySmartDeviceTotal }}</span>
                      </div>
                      <div class="mgcol_items" v-for="(item, index) in deviceData.data" :key="index">
                        <span>{{ item.name }}</span>
                        <span>{{ item.value }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
          <!-- 中间  默认无警情显示绿色 有警情显示红色-->
          <div class="baiduMapBox">
            <!-- 地图显示 -->
            <iframe
              src="baiduMap/index.html"
              scrolling="no"
              style="width: 100%; height: 100%"
              name="addressData"
              frameborder="0"
            ></iframe>
          </div>
          <!-- 右侧 -->
          <div class="mainBox_gird mainRightBox">
            <!-- 预警信息 -->
            <transition name="returnBox">
              <div class="m_gird m_warBox" v-if="returnBox == true">
                <!-- 标题 -->
                <div class="m_g_heade">
                  <div class="m_h_inner">
                    <div class="m_h_tit">
                      <span>预警信息</span>
                    </div>
                  </div>
                </div>
                <!-- 内容 -->
                <div class="mg_content">
                  <div class="mgc_content">
                    <dv-border-box-8 class="mgc_item" :color="['#e55e5e', '#e55e5e']">
                      <div @click="getHideShowBox('device')" class="mgci_btn">
                        <div class="mgci_tit">未解除安防预警</div>
                        <div class="mgci_num">{{ unresolvedEventTotal }}</div>
                      </div>
                    </dv-border-box-8>
                    <dv-border-box-8 class="mgc_item" :color="['#e55e5e', '#e55e5e']">
                      <div @click="getHideShowBox('call')" class="mgci_btn">
                        <div class="mgci_tit">未解除SOS预警</div>
                        <div class="mgci_num">{{ callUpPaidTotal }}</div>
                      </div>
                    </dv-border-box-8>
                  </div>
                  <div class="mgc_content">
                    <dv-border-box-8 class="mgc_item" :color="['#09BEBE', '#09BEBE']" :dur="0">
                      <div @click="getManagement('equipment')" class="mgci_btn">
                        <div class="mgci_tit" style="color: #09bebe">安防总预警</div>
                        <div class="mgci_num">{{ deviceEventTotal }}</div>
                      </div>
                    </dv-border-box-8>
                    <dv-border-box-8 class="mgc_item" :color="['#09BEBE', '#09BEBE']" :dur="0">
                      <div @click="getManagement('incoming')" class="mgci_btn">
                        <div class="mgci_tit" style="color: #09bebe">SOS总预警</div>
                        <div class="mgci_num">{{ callAllTotal }}</div>
                      </div>
                    </dv-border-box-8>
                  </div>
                </div>
              </div>
            </transition>
            <!-- 预警新增记录 -->
            <transition name="returnBox">
              <div class="m_gird m_returnBox" v-if="returnBox == true">
                <!-- 标题 -->
                <div class="m_g_heade">
                  <div class="m_h_inner">
                    <div class="m_h_tit">
                      <span>预警新增记录</span>
                    </div>
                    <el-date-picker
                      size="mini"
                      v-model="wranSelectYear"
                      type="year"
                      placeholder="选择年"
                      @change="selectYear"
                    >
                    </el-date-picker>
                  </div>
                </div>
                <!-- 内容 -->
                <div class="mg_content" id="mainChart"></div>
              </div>
            </transition>
            <!-- 待回访 -->
            <transition name="returnBox">
              <div class="m_gird m_returnBox" v-if="returnBox == true">
                <!-- 标题 -->
                <div class="m_g_heade">
                  <div class="m_h_inner">
                    <div class="m_h_tit">
                      <span>待回访</span>
                      <span class="m_h_dec">仅显示最近20条</span>
                    </div>
                  </div>
                </div>
                <!-- 内容 -->
                <el-row class="m_g_content">
                  <div v-if="revisitedData.length < 1" class="mgc_dec">没有待回访信息</div>
                  <div class="m_c_list" v-for="(items, index) in revisitedData" :key="index">
                    <div class="m_c_l_inner">
                      <div class="m_c_l_left">
                        <div class="m_c_l_l_tit">
                          【{{
                            items.alarmDevice == '智能燃气探测器'
                              ? '燃气'
                              : items.alarmDevice == '智能烟感探测器'
                              ? '烟感'
                              : '其它'
                          }}
                          -
                          {{
                            items.alarmContent == '55AA02015A' || items.alarmContent == '55AA00015A'
                              ? '报警'
                              : items.alarmContent == '55AA02025A' || items.alarmContent == '55AA00025A'
                              ? '低电量报警'
                              : items.alarmContent == '55AA02035A' || items.alarmContent == '55AA00035A'
                              ? '心跳/恢复正常'
                              : items.alarmContent == '55AA02045A' || items.alarmContent == '55AA00045A'
                              ? '拆除'
                              : items.alarmContent == '55AA02055A' || items.alarmContent == '55AA00055A'
                              ? '测试'
                              : items.alarmContent == '55AA02075A' || items.alarmContent == '55AA00075A'
                              ? '故障'
                              : items.alarmContent == '55AA02085A' || items.alarmContent == '55AA00085A'
                              ? '温度报警'
                              : items.alarmContent == '55AA020A5A' || items.alarmContent == '55AA000A5A'
                              ? '心跳'
                              : items.alarmContent == '55AA020D5A' || items.alarmContent == '55AA000D5A'
                              ? '防拆恢复'
                              : '其它'
                          }}】 {{ items.floorName }}号楼{{ items.unitName }}单元{{ items.layerName }}层{{
                            items.roomName
                          }}
                        </div>
                        <div class="m_c_l_l_time">{{ formatDate(items.createTime) }}</div>
                      </div>
                      <div class="m_c_l_right">
                        <div class="m_c_l_r_btn" @click="OpenCallShow(items)">回访</div>
                      </div>
                    </div>
                  </div>
                </el-row>
              </div>
            </transition>
            <!-- 待安装 -->
            <transition name="installBox">
              <div class="m_gird m_installBox" v-if="installBox == true">
                <!-- 标题 -->
                <div class="m_g_heade">
                  <div class="m_h_inner">
                    <div class="m_h_tit">
                      <span>待安装</span>
                      <span class="m_h_dec">仅显示最近20条</span>
                    </div>
                  </div>
                </div>
                <!-- 内容 -->
                <el-row class="m_g_content">
                  <div v-if="userReviewData.length < 1" class="mgc_dec">没有待安装信息</div>
                  <div class="m_c_list" v-for="(items, index) in userReviewData" :key="index">
                    <div class="m_c_l_inner">
                      <div class="m_c_l_left">
                        <div class="m_c_l_l_tit">
                          {{ items.floorName }}号楼{{ items.unitName }}单元{{ items.layerName }}层{{ items.roomName }}室
                        </div>
                        <div class="m_c_l_l_time">{{ formatDate(items.createTime) }}</div>
                      </div>
                      <div class="m_c_l_right">
                        <div class="m_c_l_r_btn m_c_l_r_moreBtn" @click="OpenReviewedShow(items)">查看</div>
                      </div>
                    </div>
                  </div>
                </el-row>
              </div>
            </transition>
            <!-- 系统公告 -->
            <transition name="installBox">
              <div class="m_gird m_installBox" v-if="installBox == true">
                <!-- 标题 -->
                <div class="m_g_heade">
                  <div class="m_h_inner">
                    <div class="m_h_tit">
                      <span>系统公告</span>
                    </div>
                  </div>
                </div>
                <!-- 内容 -->
                <el-row class="m_g_content">
                  <div class="f_g_c_tit">{{ noticeInfo.noticeTitle }}</div>
                  <div class="f_g_c_dec" v-html="noticeInfo.noticeContent"></div>
                </el-row>
              </div>
            </transition>
          </div>
          <!-- 底部 -->
          <div class="footerBox" v-if="footerBox == true">
            <div class="fb_content">
              <div class="fbc_item" @click="OpenAddUser">新增接入</div>
              <div class="fbc_item" @click="getManagement('fileList')">用户档案</div>
              <div class="fbc_item" @click="getManagement('equipment')">预警管理</div>
              <div class="fbc_item" @click="getManagement('incoming')">话务记录</div>
            </div>
          </div>
        </div>
        <!-- 设备报警消息 -->
        <div class="warnInfoBox" v-if="warnInfoBox == true">
          <el-button size="mini" class="goHomeBtn" @click="goHome('device')">返回首页</el-button>
          <div class="wib_content">
            <div class="wb_items" v-if="wdeviceData.length < 1">暂无预警记录</div>
            <div class="wb_items" v-for="(items, index) in wdeviceData" :key="index">
              <div class="wbi_icon">
                <img src="../../assets/img/warn_abnormal_w.svg" />
              </div>
              <div class="wbi_info">
                <div class="wbi_tit">
                  【{{
                    items.alarmDevice == '智能燃气探测器'
                      ? '燃气'
                      : items.alarmDevice == '智能烟感探测器'
                      ? '烟感'
                      : '其它'
                  }}
                  -
                  {{
                    items.alarmContent == '55AA02015A' || items.alarmContent == '55AA00015A'
                      ? '报警'
                      : items.alarmContent == '55AA02025A' || items.alarmContent == '55AA00025A'
                      ? '低电量报警'
                      : items.alarmContent == '55AA02035A' || items.alarmContent == '55AA00035A'
                      ? '心跳/恢复正常'
                      : items.alarmContent == '55AA02045A' || items.alarmContent == '55AA00045A'
                      ? '拆除'
                      : items.alarmContent == '55AA02055A' || items.alarmContent == '55AA00055A'
                      ? '测试'
                      : items.alarmContent == '55AA02075A' || items.alarmContent == '55AA00075A'
                      ? '故障'
                      : items.alarmContent == '55AA02085A' || items.alarmContent == '55AA00085A'
                      ? '温度报警'
                      : items.alarmContent == '55AA020A5A' || items.alarmContent == '55AA000A5A'
                      ? '心跳'
                      : items.alarmContent == '55AA020D5A' || items.alarmContent == '55AA000D5A'
                      ? '防拆恢复'
                      : '其它'
                  }}】 {{ items.floorName }}号楼{{ items.unitName }}单元{{ items.layerName }}层{{ items.roomName }}
                </div>
                <div class="wbi_time">{{ formatDate(items.createTime) }}</div>
              </div>
              <div class="wbi_btn" @click="OpenDeviceWarn(items)">查看</div>
            </div>
          </div>
        </div>
        <!-- SOS预警记录 -->
        <div class="warnInfoBox" v-if="callWarnInfoBox == true">
          <el-button size="mini" class="goHomeBtn" @click="goHome('call')">返回首页</el-button>
          <div class="wib_content">
            <div class="wb_items" v-if="callData.length < 1">暂无预警记录</div>
            <div class="wb_items" v-for="(items, index) in callData" :key="index">
              <div class="wbi_icon">
                <img src="../../assets/img/warn_abnormal_w.svg" />
              </div>
              <div class="wbi_info">
                <div class="wbi_tit">
                  {{ items.floorName }}号楼{{ items.unitName }}单元{{ items.layerName }}层{{ items.roomName }}
                </div>
                <div class="wbi_time">{{ formatDate(items.createTime) }}</div>
              </div>
              <div class="wbi_btn" @click="OpenCallWarnShow(items)">查看</div>
            </div>
          </div>
        </div>
        <!-- 新增接入 -->
        <addUser :addUser="addUserShow" @closeAddUser="showAddUser" :addUserInfo="addUserInfo"></addUser>
        <!-- 设备报警详情 -->
        <deviceWarn
          :deviceWarn="deviceWarnShow"
          @closeDeviceWarn="showDeviceWarn"
          :wdeviceDetails="wdeviceDetails"
        ></deviceWarn>
        <!-- 解除报警 -->
        <warnSolve :warnSolve="warnSolveShow" @closeWarnSolve="showWarnSolve"></warnSolve>
        <!-- 电话详情 -->
        <callWarn :callWarn="callWarnShow" @closeCallWarn="showCallWarn" :callDetails="callDetails"></callWarn>
        <!-- 待安装详情 -->
        <reviewed :reviewed="reviewedShow" @closeReviewed="showReviewed" :userReviewInfos="userReviewInfos"></reviewed>
        <!-- 拨打电话/来电 -->
        <openCall :openCall="openCallShow" @closeOpenCall="showCall"></openCall>
      </div>
    </div>
    <!-- 报警提示音 -->
    <audio controls="controls" hidden src="../../assets/music/alarm_music.wav" ref="alarmMusic"></audio>
    <!-- 报警音频 -->
    <audio controls="controls" hidden src="../../assets/music/baojingyiyin.mp3" ref="baojingyiyin"></audio>
    <!-- 消息提示音 -->
    <audio controls="controls" hidden src="../../assets/music/clew_tone.wav" ref="clewTone"></audio>
    <!-- 超时提示音 -->
    <audio controls="controls" hidden src="../../assets/music/overtime.wav" ref="overtime"></audio>
  </div>
</template>
<script>
// 新增接入
import AddUser from '@/components/home/addUser'
// 设备报警详情
import DeviceWarn from '@/components/home/deviceWarn'
// 解除报警
import WarnSolve from '@/components/home/warnSolve'
// 电话详情
import CallWarn from '@/components/home/callWarn'
// 待安装详情
import Reviewed from '@/components/home/reviewed'
// 拨打电话
import OpenCall from '@/components/home/openCall'
// 设备报警列表
import {
  queryDeviceWarning,
  queryCallWarning,
  queryRoomIdToProperty,
  queryCallThisMonthEventList,
  queryCallDataList,
  queryThisMonthEventList,
  queryIsNullEventList,
  getWarnDataInfo
} from '@/api/warning.js'
// 居民
import {
  queryProcessIng,
  queryProList,
  queryFamilyList,
  queryNewProcess,
  queryThisMonthUserProfileList,
  queryThisMonthUserReviewList,
  getUserFindByAgeList
} from '@/api/archives.js'
// 物业基础信息
import { getAreaInfo, queryNoticeClassList, queryNoticeClassContentList } from '@/api/property.js'
import { getPropertySmartDeviceList, getPropertySmartPhoneList } from '@/api/device.js'
import { getCityCode, getProvinceCode, getAreaCode, getWeather } from '@/api/public.js'
// 从session中获取token信息
import session from '@/utils/session.js'
import { loginOutToTime } from '@/api/login.js'
import * as echarts from 'echarts'
// 定义websoket服务
let ws

export default {
  components: {
    AddUser: AddUser,
    DeviceWarn: DeviceWarn,
    WarnSolve: WarnSolve,
    CallWarn: CallWarn,
    Reviewed: Reviewed,
    OpenCall: OpenCall
  },
  data() {
    return {
      fullscreen: false,
      date: new Date(),
      // 控制进入页面时动画
      footerBox: false,
      warnInfoBox: false,
      callWarnInfoBox: false,
      mainBoxGird: false,
      baseBox: false,
      dataBox: false,
      installBox: false,
      returnBox: false,
      deviceBox: false,
      deviceWarnBox: false,
      callWarnBox: false,
      noticeBox: false,
      // 新增接入
      addUserShow: false,
      // 设备报警详情
      deviceWarnShow: false,
      // 呼入记录 true  呼出记录 false
      showCallInfo: true,
      inCallOpacity: '',
      exCallOpacity: '',
      callDetails: {},
      // 解除报警
      warnSolveShow: false,
      // 电话详情
      callWarnShow: false,
      // 待安装详情
      reviewedShow: false,
      // 拨打电话
      openCallShow: false,
      // 首页根据是否有警情显示对应背景
      backgroundImage: '../../assets/img/home_main_img.svg',
      pageBox: {
        background: ''
      },
      // 0 显示正常盾牌 1 显示红色盾牌
      eventType: '',
      // 分页
      query: {
        page: 1,
        pageSize: 20
      },
      // 预警新增记录图表
      warnAddData: {},
      // 设备报警记录
      wdeviceData: [],
      wdeviceDetails: {},
      wranSelectYear: new Date(),
      // 电话预警
      callData: [],
      // 待回访列表
      revisitedData: [],
      // 待安装
      userReviewData: [],
      userReviewInfos: {},
      userReviewListTotal: 0,
      // 获取物业信息
      propertyInfo: {},
      // token信息
      userInfo: {},
      // 已安装数量
      userProfileSize: 0,
      userProfileList: [],
      // 接入率
      accessRate: 0,
      // 服务人员数量
      severFamilySize: 0,
      // 本月新增 总量
      thisMonthUserProfileToUserReviewTotal: 0,
      // 本月新增 安装成功
      userProfileThisMonthTotal: 0,
      // 本月新增 待安装
      userReviewThisMonthTotal: 0,
      propertyId: '',
      // 消息校验获取的propertyId
      msgPropertyId: '',
      msgInfo: {},
      // 总报警次数
      deviceEventTotal: 0,
      // 本月新增
      deviceThisMonthEventTotal: 0,
      unresolvedEventTotal: 0,
      // 来电总数
      callAllTotal: 0,
      // 本月新增
      callThisMonthEventTotal: 0,
      callUpPaidTotal: 0,
      // 公告内容
      noticeInfo: {},
      // 电话总数
      propertySmartPhoneTotal: 0,
      // 设备总数
      propertySmartDeviceTotal: 0,
      // 设备在线
      onDeviceTotal: 0,
      // 设备离线
      offDeviceTotal: 0,
      // 公告内容
      noticeInfo: {},
      // 老人数据
      oldData: {
        data: [
          {
            name: '60以下',
            value: 0
          },
          {
            name: '60-69岁',
            value: 0
          },
          {
            name: '70-79岁',
            value: 0
          },
          {
            name: '80-89岁',
            value: 0
          },
          {
            name: '90岁+',
            value: 0
          }
        ],
        radius: 45,
        activeRadius: 55,
        lineWidth: 12,
        digitalFlopStyle: {
          fontSize: 14,
          fill: '#fff'
        }
      },
      // 设备信息 电话
      devicePhoneData: {
        data: [
          {
            name: '正常设备',
            value: 0
          },
          {
            name: '故障设备',
            value: 0
          }
        ],
        radius: 45,
        activeRadius: 55,
        lineWidth: 12,
        digitalFlopStyle: {
          fontSize: 14,
          fill: '#fff'
        }
      },
      // 设备信息 设备
      deviceData: {
        data: [
          {
            name: '在线设备',
            value: 0
          },
          {
            name: '离线设备',
            value: 0
          },
          {
            name: '故障设备',
            value: 0
          }
        ],
        radius: 45,
        activeRadius: 55,
        lineWidth: 12,
        digitalFlopStyle: {
          fontSize: 14,
          fill: '#fff'
        }
      }
    }
  },
  created() {
    this.userInfo = session.getUserInfo()
    this.propertyId = this.userInfo.propertyId
    this.mobile = this.userInfo.mobile
  },

  mounted() {
    let that = this
    // 控制进入页面时动画
    this.getShowBox()
    this.propertyInit()
    this.gitInCallWarn()
    this.gitEventInfo()
    this.deviceInit()
    this.callInit()
    this.userReviewInit()
    this.getUserProfileList()
    this.getSeverFamilyList()
    this.propertyInits()
    this.getThisMonthUserProfileList()
    this.getThisMonthUserReviewList()
    this.getNoticeList() // 获取公告内容
    this.getPropertySmartDeviceList() // 设备总数
    this.getPropertySmartPhoneList() // 智能电话
    this.getUserFindByAgeList() // 老人年龄分布
    this.getWearher()
    this.getWarnDataInfo() // 预警新增记录
    this.selectYear(this.wranSelectYear) // 预警选择年份 默认当年
    //显示当前日期时间
    let _this = this // 声明一个变量指向Vue实例this，保证作用域一致
    this.timer = setInterval(() => {
      _this.date = new Date() // 修改数据date
    }, 1000)
    // setTimeout(() => {
    //   that.initChart()
    // }, 1000)
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer) // 在Vue实例销毁前，清除我们的定时器
    }
  },
  methods: {
    async logout() {
      let result = await loginOutToTime(this.query, this.userInfo.id)
      if (result.success) {
        sessionStorage.removeItem('session')
        sessionStorage.removeItem('userInfo')
        this.$router.push({ path: '/' })
      }
    },
    // 查询天气预报
    async getWearher() {
      // let res = await getWeather(110101)
      // console.log('res', res)
      // this.$axios({
      //   method: 'get',
      //   url: '/baidu/api.map.baidu.com/weather/v1/?district_id=110101&data_type=all&ak=PjRHApVAjVvY90u8otcuDNMgw67gnSVG',
      //   headers: {
      //     'Content-Type': 'application/javascript'
      //   },
      //   changeOrigin: true, //是否跨域
      //   secure: false //如果是https接口，需要配置这个参数
      // }).then((res) => {
      //   console.log(res.data)
      // })
    },
    enterFullscreen() {
      this.fullscreen = true
      const element = document.documentElement
      if (element.requestFullscreen) {
        element.requestFullscreen()
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen()
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen()
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen()
      }
    },
    async getUserFindByAgeList() {
      const { oldData } = this
      let res = await getUserFindByAgeList(this.query)
      this.oldData.data[0].value = res.data.age1
      this.oldData.data[1].value = res.data.age2
      this.oldData.data[2].value = res.data.age3
      this.oldData.data[3].value = res.data.age4
      this.oldData.data[4].value = res.data.age5
      this.oldData = { ...this.oldData }
    },
    // 退出全屏
    exitFullscreen() {
      this.fullscreen = false
      //W3C
      if (document.exitFullscreen) {
        document.exitFullscreen()
      }
      //FireFox
      else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      }
      //Chrome等
      else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
      //IE11
      else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
      this.fullscreen = false
    },
    // 向iframe传值
    async onBtn() {
      let that = this
      let iframe = window.frames['addressData']
      let spJson = {
        province: this.propertyInfo.propertyProvince,
        city: this.propertyInfo.propertyCity,
        area: this.propertyInfo.propertyArea,
        spAddress: this.propertyInfo.propertyAddress
      }
      // 遍历用户列表
      let newList = []
      for (let i = 0; i < this.userProfileList.length; i++) {
        let itemData = {
          id: null,
          eventId: null,
          roomId: null,
          ownerName: null,
          ownerPhone: null,
          add: null,
          warnInfo: '正常',
          deviceStatus: false,
          callStatus: false,
          time: null
        }
        itemData.id = this.userProfileList[i].id
        itemData.roomId = this.userProfileList[i].roomId
        itemData.ownerName = this.userProfileList[i].ownerName
        itemData.ownerPhone = this.userProfileList[i].ownerPhone
        itemData.add =
          this.userProfileList[i].propertyProvince +
          this.userProfileList[i].propertyCity +
          this.userProfileList[i].propertyArea +
          this.userProfileList[i].propertyName +
          this.userProfileList[i].villageName +
          this.userProfileList[i].floorName +
          '号楼' +
          this.userProfileList[i].unitName +
          '单元' +
          this.userProfileList[i].layerName +
          '层' +
          this.userProfileList[i].roomName +
          '室' +
          newList.push(itemData)
      }
      let deviceStatus = false
      let callStatus = false
      for (let i = 0; i < newList.length; i++) {
        for (let t = 0; t < this.wdeviceData.length; t++) {
          if (newList[i].roomId == this.wdeviceData[t].roomId) {
            newList[i].deviceStatus = true
            newList[i].warnInfo = this.wdeviceData[t].alarmDevice + '异常'
            newList[i].eventId = this.wdeviceData[t].id
            deviceStatus = true
            newList[i].time = that.formatDate(this.wdeviceData[i].createTime)
          }
        }
        for (let t = 0; t < this.callData.length; t++) {
          if (newList[i].roomId == this.callData[t].roomId) {
            newList[i].callStatus = true
            newList[i].warnInfo = this.callData[t].alarmDevice + '未接听'
            newList[i].eventId = this.callData[t].id
            callStatus = true
            newList[i].time = that.formatDate(this.callData[i].createTime)
          }
        }
      }
      let data = {
        add: spJson,
        userAddList: newList
      }
      iframe.onChangeStr(data)
      if (deviceStatus) {
        this.warnInfoBox = false
        this.footerBox = true
        this.mainBoxGird = true
        this.baseBox = true
        this.dataBox = true
        this.installBox = true
        this.returnBox = true
        this.deviceBox = true
        this.deviceWarnBox = true
        this.callWarnBox = true
        this.noticeBox = true
        that.getHideShowBox('device')
      }
      if (callStatus) {
        this.callWarnInfoBox = false
        this.footerBox = true
        this.mainBoxGird = true
        this.baseBox = true
        this.dataBox = true
        this.installBox = true
        this.returnBox = true
        this.deviceBox = true
        this.deviceWarnBox = true
        this.callWarnBox = true
        this.noticeBox = true
        that.getHideShowBox('call')
      }
      // 接受iframe值
      window.addEventListener('message', function (e) {
        var res = e.data
        let eventId = res.eventId
        let deviceStatus = res.deviceStatus
        let callStatus = res.callStatus
        if (!!res) {
          if (deviceStatus) {
            const item = that.wdeviceData.filter((i) => i.id == eventId)
            that.OpenDeviceWarn(item[0])
          } else if (callStatus) {
            const item = that.callData.filter((i) => i.id == eventId)
            that.OpenDeviceWarn(item[0])
          } else {
            that.$message.success('暂无报警消息')
          }
        }
      })
    },
    dateFormat(time) {
      var date = new Date(time)
      var year = date.getFullYear()
      /* 在日期格式中，月份是从0开始的，因此要加0
       * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
       * */
      var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
      var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      // 拼接
      return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
    },
    propertyInits() {
      this.lockReconnect = false // 避免重连的机制
      this.heartBeatClock = null // websocket的setTimeout函数
      this.try = null // 尝试重连
      let _this = this
      if (typeof WebSocket === 'undefined') {
        console.log('您的浏览器不支持WebSocket')
      } else {
        // 用于接收结果的webcosket 服务
        // let socketUrl2 = 'ws://kwangda.com:8090/propertyWebsocket/' + this.mobile
        let socketUrl2 = 'wss://kwangda.com:8090/propertyWebsocket/' + this.propertyId
        // let socketUrl2 = 'ws://127.0.0.1:8054'
        if (ws != null) {
          ws.close()
          ws = null
        }
        // 开启webSocket服务
        ws = new WebSocket(socketUrl2)
        console.log('正在开启...')
        // 打开事件
        ws.onopen = function () {
          console.log('设备WebSocket已打开')
        }
        // 浏览器手消息，获得从服务端发过来的消息事件
        ws.onmessage = function (msg) {
          if (msg.data == '888888') {
            console.log('WebSocket正常')
          } else {
            let msgInfo = JSON.parse(msg.data) // 对收到的数据进行解析
            _this.getPropertyIdInfo(msgInfo)
            _this.deviceInits(msgInfo) // 接收报警
            _this.eventProcessingTime(msgInfo) // 接收事件超时提醒
          }
        }
        ws.onclose = (e) => {
          console.log('设备WebSocket已关闭')
          clearTimeout(_this.try)
          _this.try = setTimeout(() => {
            reconnectWebSocket()
          }, 5000)
        }
        ws.onerror = (e) => {
          console.log('设备WebSocket异常关闭')
          clearTimeout(_this.try)
          _this.try = setTimeout(() => {
            _this.reconnectWebSocket()
          }, 5000)
        }
        function reconnectWebSocket() {
          console.log('设备重连中...')
          if (_this.lockReconnect) {
            return
          }
          _this.lockReconnect = true
          // 没连接上会一直重连，设置延迟避免请求过多
          _this.heartBeatClock && clearTimeout(this.heartBeatClock)
          _this.heartBeatClock = setTimeout(() => {
            _this.propertyInits()
            _this.lockReconnect = false
          }, 5000)
        }
        //心跳检测 与服务端约定666666为心跳检测
        //  每隔30s后向服务端发送消息 服务返回888888为正常
        var postBackMes30
        if (!postBackMes30) {
          window.postBackMes30 = setInterval(() => {
            //这里放 websocket的传送信息方法
            var jsonData = { propertyId: this.propertyId, message: '666666' }
            ws.send(JSON.stringify(jsonData))
          }, 30 * 1000)
        }
      }
    },

    // 根据报警事件获取弹框信息
    async deviceInits(msgInfo) {
      let propertyId = this.userInfo.propertyId
      if (msgInfo.deviceInfo.propertyId == propertyId) {
        let result = await queryDeviceWarning(this.query)
        if (result.success) {
          this.wdeviceData = result.data.rows
          this.wdeviceDetails = this.wdeviceData[0]
          let items = this.wdeviceDetails
          // 弹出报警弹框
          this.OpenDeviceWarn(items)
          this.onBtn() // 向iframe传值
          // 播放报警提示音
          this.$refs.alarmMusic.currentTime = 0
          this.$refs.alarmMusic.play()
          this.$refs.baojingyiyin.currentTime = 0
          this.$refs.baojingyiyin.play()
        }
        // 更新设备报警记录列表
        this.deviceInit()
      } else {
        console.log('没有我的数据')
      }
    },
    // 音频
    play() {
      this.$refs.alarmMusic.play()
    },
    // 接收企业信息
    async getPropertyIdInfo(msgInfo) {
      let result = await queryRoomIdToProperty(msgInfo.sysMessageInfo.roomId)
      if ((result.data.id = this.propertyId)) {
        this.msgInfo = msgInfo.sysMessageInfo
        // 更新设备报警记录列表
        this.deviceInit()
        const h = this.$createElement
        this.$notify({
          title: this.msgInfo.msgTit,
          message: this.msgInfo.msgContent,
          position: 'bottom-right',
          duration: 0
        })
        // 关闭报警弹框
        this.deviceWarnShow = false
        // 播放消息提示音
        this.$refs.clewTone.currentTime = 0
        this.$refs.clewTone.play()
        this.onBtn() // 向iframe传值
      } else {
        console.log('没有我的数据')
      }
    },
    // 接收事件超时提醒
    eventProcessingTime(msgInfo) {
      if (msgInfo.sysPropertyMessage.propertyId == this.propertyId) {
        this.msgInfo = msgInfo.sysPropertyMessage
        // 更新设备报警记录列表
        this.deviceInit()
        const h = this.$createElement
        this.$notify.error({
          title: this.msgInfo.msgTit,
          message: this.msgInfo.msgContent,
          position: 'bottom-left',
          duration: 0
        })
        // 播放超时提示音
        this.$refs.overtime.currentTime = 0
        this.$refs.overtime.play()
      } else {
        console.log('没有我的数据')
      }
    },
    // 获取物业基本信息
    async propertyInit() {
      let result = await getAreaInfo(this.userInfo.propertyId)
      if (result.success) {
        this.propertyInfo = result.data
        // 城市
        let resProvince = await getProvinceCode(this.propertyInfo.propertyProvince)
        if (resProvince.success) {
          this.propertyInfo.propertyProvince = resProvince.data.name
        }
        let res = await getCityCode(this.propertyInfo.propertyCity)
        if (res.success) {
          this.propertyInfo.propertyCity = res.data.name
        }
        let resArea = await getAreaCode(this.propertyInfo.propertyArea)
        if (resArea.success) {
          this.propertyInfo.propertyArea = resArea.data.name
        }
        this.onBtn() // 向iframe传值
      }
    },
    // 获取已安装用户列表 获得数量
    async getUserProfileList() {
      let result = await queryProList(this.query)
      if (result.success) {
        this.userProfileSize = result.data.total
        this.userProfileList = result.data.rows
      }
    },
    // 获取当前物业全部服务人数
    async getSeverFamilyList() {
      let result = await queryFamilyList(this.query)
      if (result.success) {
        this.severFamilySize = result.data.total
      }
    },
    // 本月新增 安装成功
    async getThisMonthUserProfileList() {
      let result = await queryThisMonthUserProfileList(this.query)
      if (result.success) {
        this.userProfileThisMonthTotal = result.data.total
      }
    },
    // 本月新增 待安装
    async getThisMonthUserReviewList() {
      let result = await queryThisMonthUserReviewList(this.query)
      if (result.success) {
        this.userReviewThisMonthTotal = result.data.total
      }
    },

    // 设备预警
    async deviceInit() {
      let result = await queryDeviceWarning(this.query)
      if (result.success) {
        // this.wdeviceData = result.data.rows
        this.deviceEventTotal = result.data.total
        // 根据设备信息显示当前设备报警记录
        this.revisitedData = result.data.rows.filter((i) => i.recycleTime == null)
        let warnInfo = result.data.rows.filter((i) => i.resolver == null)
        this.wdeviceData = warnInfo
        if (warnInfo.length > 0) {
          // 有警情 改变主图状态
          let res = 1
          this.gitEventInfo(res)
        } else {
          // 无警情 改变主图状态
          let res = null
          this.gitEventInfo(res)
        }
      }
      // 本月新增
      let result2 = await queryThisMonthEventList(this.query)
      if (result2.success) {
        this.deviceThisMonthEventTotal = result2.data.total
      }
      // 未解决报警
      let result3 = await queryIsNullEventList(this.query)
      if (result3.success) {
        this.unresolvedEventTotal = result3.data.total
      }
    },
    // 请求是否有报警信息
    gitEventInfo(res) {
      if (res == 1) {
        // 有警情
        this.eventType = 1
        this.pageBox.background = 'radial-gradient(circle at 50% 50%, #3F342C, #000000)'
      } else {
        // 无警情
        this.eventType = 0
        this.pageBox.background = 'radial-gradient(circle at 50% 50%, #2c3f36, #000000)'
      }
    },
    // 电话预警
    async callInit() {
      let result = await queryCallWarning(this.query)
      if (result.success) {
        this.callData = result.data.rows.filter((i) => i.resolver == '未接听')
        this.callAllTotal = result.data.total
      }
      // 本月新增
      let result2 = await queryCallThisMonthEventList(this.query)
      if (result2.success) {
        this.callThisMonthEventTotal = result2.data.total
      }
      // 未接听
      let upPaidResolver = '未接听'
      let result3 = await queryCallDataList(upPaidResolver, this.query)
      if (result3.success) {
        this.callUpPaidTotal = result3.data.total
      }
    },
    // 待安装
    async userReviewInit() {
      let result = await queryProcessIng(this.query)
      if (result.success) {
        this.userReviewData = result.data.rows
        this.userReviewListTotal = result.data.total
      }
    },
    // 根据物业查询所有智能设备列表
    async getPropertySmartDeviceList() {
      const res = await getPropertySmartDeviceList(this.query)
      if (res.success) {
        const { deviceData } = this
        this.propertySmartDeviceTotal = res.data.total
        let onDevice = res.data.rows.filter((i) => i.event_type == 1)
        // this.onDeviceTotal = onDevice.length
        this.deviceData.data[0].value = onDevice.length
        let offDevice = res.data.rows.filter((i) => i.event_type == 0)
        // this.offDeviceTotal = offDevice.length
        this.deviceData.data[1].value = offDevice.length
        this.deviceData = { ...this.deviceData }
      }
    },
    // 根据物业查询所有智能设备列表
    async getPropertySmartPhoneList() {
      const res = await getPropertySmartPhoneList(this.query)
      if (res.success) {
        const { devicePhoneData } = this
        this.propertySmartPhoneTotal = res.data.total
        this.devicePhoneData.data[0].value = res.data.total
        this.devicePhoneData = { ...this.devicePhoneData }
      }
    },
    // 控制进入页面时动画
    getShowBox() {
      this.footerBox = !this.footerBox
      this.mainBoxGird = !this.mainBoxGird
      this.baseBox = !this.baseBox
      this.dataBox = !this.dataBox
      this.installBox = !this.installBox
      this.returnBox = !this.returnBox
      this.deviceBox = !this.deviceBox
      this.deviceWarnBox = !this.deviceWarnBox
      this.callWarnBox = !this.callWarnBox
      this.noticeBox = !this.noticeBox
    },
    // 返回首页
    goHome(data) {
      this.warnInfoBox = false
      this.callWarnInfoBox = false
      this.getShowBox()
      this.selectYear(this.wranSelectYear) // 预警选择年份 默认当年
    },
    // 隐藏数据控件
    getHideShowBox(data) {
      if (data == 'device') {
        this.warnInfoBox = !this.warnInfoBox
      } else {
        this.callWarnInfoBox = !this.callWarnInfoBox
      }
      this.footerBox = !this.footerBox
      this.mainBoxGird = !this.mainBoxGird
      this.baseBox = !this.baseBox
      this.dataBox = !this.dataBox
      this.installBox = !this.installBox
      this.returnBox = !this.returnBox
      this.deviceBox = !this.deviceBox
      this.deviceWarnBox = !this.deviceWarnBox
      this.callWarnBox = !this.callWarnBox
      this.noticeBox = !this.noticeBox
    },
    // 新增接入
    OpenAddUser() {
      this.addUserShow = true
    },
    showAddUser() {
      this.addUserShow = false
    },
    // 新增接入
    async addUserInfo(data) {
      let result = await queryNewProcess(data)
      if (result.success) {
        if (result.success) {
          this.$message.success('新增接入成功')
          this.userReviewInit()
          this.addUserShow = false
        } else {
          this.$message.error('新增失败')
        }
      }
    },
    // 跳转值管理中心
    getManagement(data) {
      let openNew = this.$router.resolve({ path: '/' + data })
      window.open(openNew.href, '_blank')
    },
    // 设备报警详情
    OpenDeviceWarn(items) {
      this.deviceWarnShow = true
      this.wdeviceDetails = items
    },
    showDeviceWarn() {
      this.deviceWarnShow = false
    },
    // 呼入呼出切换请求
    gitInCallWarn() {
      this.inCallOpacity = 1
      this.exCallOpacity = 0.5
      this.showCallInfo = true
    },
    gitExCallWarn() {
      this.inCallOpacity = 0.5
      this.exCallOpacity = 1
      this.showCallInfo = false
    },
    // 解除报警
    OpenWarnSolve() {
      this.warnSolveShow = true
    },
    showWarnSolve() {
      this.warnSolveShow = false
    },
    // 电话详情
    OpenCallWarnShow(items) {
      this.callWarnShow = true
      this.callDetails = items
    },
    showCallWarn() {
      this.callWarnShow = false
    },
    // 待安装详情
    OpenReviewedShow(items) {
      this.reviewedShow = true
      this.userReviewInfos = items
    },
    showReviewed() {
      this.reviewedShow = false
    },
    // 拨打电话
    OpenCallShow(items) {
      // this.openCallShow = true
      this.deviceWarnShow = true
      this.wdeviceDetails = items
    },
    showCall() {
      this.openCallShow = false
    },
    async getNoticeList() {
      const res = await queryNoticeClassList()
      const obj = res.data.find((d) => d.className == '系统公告')
      if (res.success) {
        const ress = await queryNoticeClassContentList(obj.id, this.query)
        if (ress.success) {
          this.noticeInfo = ress.data.rows[0]
        }
      }
    },
    // 预警新增数据图表
    selectYear(data) {
      this.wranSelectYear = this.fmtTime('yyyy', data)
      let date = {
        startDate: this.fmtTime('yyyy', data) + '-01-01',
        endDate: this.fmtTime('yyyy', data) + '-12-31'
      }
      this.getWarnDataInfo(date)
    },
    async getWarnDataInfo(date) {
      let res = await getWarnDataInfo(date.startDate, date.endDate)
      this.initChart(res.data)
    },
    initChart(resData) {
      var app = {}
      var chartDom = document.getElementById('mainChart')
      var myChart = echarts.init(chartDom)
      var option
      app.config = {
        rotate: 90,
        align: 'left',
        verticalAlign: 'middle',
        position: 'insideBottom',
        distance: 12,
        onChange: function () {
          const labelOption = {
            rotate: app.config.rotate,
            align: app.config.align,
            verticalAlign: app.config.verticalAlign,
            position: app.config.position,
            distance: app.config.distance
          }
          myChart.setOption({
            series: [
              {
                label: labelOption
              },
              {
                label: labelOption
              },
              {
                label: labelOption
              },
              {
                label: labelOption
              }
            ]
          })
        }
      }
      const labelOption = {
        show: true,
        position: app.config.position,
        distance: app.config.distance,
        align: app.config.align,
        verticalAlign: app.config.verticalAlign,
        rotate: app.config.rotate,
        formatter: '{c}  {name|{a}}',
        fontSize: 12,
        rich: {
          name: {}
        }
      }
      option = {
        grid: {
          top: 1
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: [
          {
            type: 'category',
            axisTick: { show: false },
            data: resData.map((d) => d.month)
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '',
            type: 'bar',
            barGap: 0,
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: resData.map((d) => d.device)
          },
          {
            name: '',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: resData.map((d) => d.call)
          }
        ]
      }
      option && myChart.setOption(option)
    }
  }
}
</script>
<style lang="less" scoped>
.pageBox {
  width: 100%;
  height: 100%;
  color: #fff;
  font-size: 14px;
  letter-spacing: 1px;
}
.pageInner {
  width: 100%;
  height: 100%;
  background-image: url('../../assets/img/home_bg_grid.svg');
  background-size: 100%;
}
.pageImgBg {
  width: 100%;
  height: 100%;
}
.headeShow {
  position: fixed;
  top: 0;
  height: 100px;
  width: 100%;
  // background: #000;
  background: linear-gradient(0deg, rgba(16, 153, 153, 0) 0, rgba(1, 27, 27, 1) 96%);
  z-index: 998;
}
.headeBox {
  width: 100%;
  background: linear-gradient(90deg, rgba(1, 27, 27, 1) 30%, rgba(16, 153, 153, 0.9) 50%, rgba(1, 27, 27, 1) 80%);
  position: relative;
  z-index: 999;
  height: 55px;
  padding-top: 8px;
  .h_inner {
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-image: url(../../assets/img/home_title_bg.svg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: 999;
    .h_i_leftBox {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      width: 27%;
      padding-top: 10px;
      .h_i_l_map {
        display: flex;
        align-items: center;
        margin-right: 16px;
        .h_i_l_m_icon {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
      }
    }
    .h_i_m_tit {
      font-size: 40px;
      font-weight: 500;
      letter-spacing: 12px;
      text-align: center;
    }
    .h_i_rightBox {
      width: 27%;
      display: flex;
      justify-content: space-between;
      padding-top: 10px;
      .hir_left {
        display: flex;
        align-items: center;
        .h_i_l_map {
          display: flex;
          align-items: center;
          margin-right: 16px;
        }
      }
      .h_i_r_a_txt {
        transform: skew(42deg);
        cursor: pointer;
      }
      .h_i_r_addBtn,
      .h_i_r_adminBtn {
        width: 46%;
        height: 32px;
        margin-top: 12px;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        line-height: 32px;
        transform: skew(-42deg);
        -webkit-transform: skew(-42deg);
        -moz-transform: skew(-42deg);
        -o-transform: skew(-42deg);
        -ms-transform: skew(-42deg);
        background-color: #09be64;
      }
      .h_i_r_adminBtn {
        background-color: #078b8b;
        margin-right: 24px;
      }
      .h_i_r_adminBtn:hover {
        background-color: #056767;
      }
      .h_i_r_addBtn:hover {
        background-color: #078b49;
      }
    }
  }
}
.mainBox {
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
}
.baiduMapBox {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 0;
}
.m_baseBox {
  height: calc((100vh - 418px) / 2);
  overflow: auto;
}
.m_dataBox {
  height: calc((100vh - 372px) / 2);
  overflow: auto;
}
.m_oldBox {
  height: 300px;
}
.m_warBox {
  height: 220px;
}
.m_returnBox,
.m_installBox {
  height: calc((100vh - 332px) / 4);
  overflow: auto;
}
.shieldBg {
  background-image: url('../../assets/img/home_main_img.svg');
}
.shieldRedBg {
  background-image: url('../../assets/img/home_main_redImg.svg');
}
.mainBox_min {
  width: calc(100vw - 816px);
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .m_m_midImg {
    width: 100%;
    height: 100%;
    margin: auto;
  }
  img {
    animation: jumpBoxHandler 2s infinite;
  }
  @keyframes jumpBoxHandler {
    0% {
      transform: translate(0px, 0px); /*开始位置*/
    }
    50% {
      transform: translate(0, 15px); /* 可配置跳动方向 */
    }
    100% {
      transform: translate(0px, 0px); /*结束位置*/
    }
  }
}
.mainLeftBox {
  background: linear-gradient(90deg, rgba(19, 47, 47, 1) 10%, rgba(19, 47, 47, 0.9) 60%, rgba(19, 47, 47, 0.3) 90%);
}
.mainRightBox {
  background-image: linear-gradient(
    -90deg,
    rgba(19, 47, 47, 1) 4%,
    rgba(19, 47, 47, 0.9) 60%,
    rgba(19, 47, 47, 0.3) 90%
  );
}
.mainBottomBox {
  background-image: linear-gradient(0deg, rgba(19, 47, 47, 1) 4%, rgba(19, 47, 47, 0.9) 60%, rgba(19, 47, 47, 0.3) 90%);
}
.mainBox_gird {
  z-index: 999;
  width: 384px;
  .m_gird {
    background: rgba(1, 27, 27, 0.7);
    margin-bottom: 8px;
    .m_g_heade {
      color: #a3f0f0;
      display: flex;
      align-items: center;
      height: 44px;
      font-size: 16px;
      font-weight: 500;
      padding: 0 16px;
      background-image: linear-gradient(90deg, rgba(0, 255, 255, 0.05) 4%, rgba(0, 255, 255, 0) 80%);
      .m_h_inner {
        display: flex;
        align-items: center;
        height: 32px;
        width: 100%;
        .m_h_icon {
          width: 22px;
          height: 22px;
          margin-right: 8px;
        }
        .m_h_tit {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }
        .m_h_dec {
          font-size: 14px;
          font-weight: 400;
          opacity: 0.5;
        }
      }
    }
    .m_content {
      padding: 16px;
      .m_c_grid {
        display: flex;
        align-items: center;
        margin: 15px 0;
        .m_c_g_icon {
          width: 48px;
          height: 48px;
          margin-right: 16px;
        }
        .m_c_g_t_number {
          font-size: 24px;
          font-weight: 500;
        }
        .m_c_g_t_tit {
          font-size: 14px;
          opacity: 0.5;
        }
      }
    }
    .mg_content {
      padding: 8px;
      .mgc_content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .mgc_item {
          text-align: center;
          padding: 16px 0;
          .mgci_tit {
            color: #e55e5e;
            font-weight: 500;
          }
          .mgci_num {
            font-size: 20px;
            font-weight: 500;
          }
          .mgci_btn:hover {
            opacity: 0.7;
            cursor: pointer;
          }
        }
      }
      .warnAddCharBox {
        width: 100%;
        height: 100%;
      }
    }
    .mgc_old {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .mgcol_items {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 180px;
        border-bottom: 1px dashed rgba(255, 255, 255, 0.15);
        line-height: 32px;
      }
    }
    .m_g_content {
      .f_g_c_tit {
        padding: 16px;
      }
      .f_g_c_dec {
        padding: 0 16px;
        opacity: 0.5;
      }
      .mgc_dec {
        opacity: 0.4;
        text-align: center;
        padding: 24px 0;
      }
      .m_c_list {
        border-bottom: 1px solid rgba(123, 123, 141, 0.1);
        .m_c_l_inner {
          padding: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .m_c_l_l_time {
            font-size: 12px;
            opacity: 0.5;
            padding-top: 4px;
          }
          .m_c_l_r_btn {
            width: 54px;
            padding: 4px 0;
            background: #09be64;
            border-radius: 50px;
            text-align: center;
            cursor: pointer;
          }
          .m_c_l_r_moreBtn {
            background: #333d3d;
          }
        }
      }
    }
  }
}
.f_deviceBox,
.f_deviceWarnBox,
.f_callWarnBox,
.f_noticeBox {
  height: 257px;
  overflow: auto;
}
.footerBox {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 998;
  display: flex;
  justify-content: center;
  .fb_content {
    width: calc(100vw - 760px);
    height: 62px;
    background-image: url('../../assets/img/home_nav_bg.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .fbc_item {
      font-size: 16px;
      margin: 0 32px;
      color: #a3f0f0;
    }
    .fbc_item:hover {
      color: #fff;
      cursor: pointer;
    }
  }
}
// 报警消息列表
.goHomeBtn {
  position: fixed;
  top: 100px;
  right: 24px;
}
.warnInfoBox {
  position: fixed;
  top: 120px;
  left: 0;
  width: 450px;
  height: calc(100vh - 200px);
  z-index: 9999;
  padding: 24px;
  overflow: auto;
  .wb_items {
    background: #e55e5e;
    border-radius: 16px;
    box-shadow: 0 0 20px rgba(229, 94, 94, 0.5);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    margin-bottom: 16px;
    .wbi_icon {
      width: 40px;
      height: 40px;
    }
    .wbi_tit {
      font-size: 16px;
      font-weight: 500;
      padding-bottom: 4px;
    }
    .wbi_time {
      opacity: 0.7;
      padding-left: 10px;
    }
    .wbi_info {
      width: 298px;
    }
    .wbi_btn {
      width: 60px;
      background: #fff;
      border-radius: 44px;
      color: #333d3d;
      line-height: 32px;
      text-align: center;
      font-weight: 500;
      cursor: pointer;
    }
    .wbi_btn:hover {
      background: rgba(255, 255, 255, 0.7);
    }
  }
}
/**
控制元素展开动画
*/
.baseBox-enter-active,
.baseBox-leave-active,
.returnBox-enter-active,
.returnBox-leave-active,
.deviceBox-enter-active,
.deviceBox-leave-active {
  transition: opacity 0.5s;
  transition: transform 0.5s;
}
.baseBox-enter,
.baseBox-leave-to,
.dataBox-enter,
.dataBox-leave-to {
  transform: translateX(-384px);
  opacity: 0;
}
.dataBox-enter-active,
.dataBox-leave-active,
.installBox-enter-active,
.installBox-leave-active,
.deviceWarnBox-enter-active,
.deviceWarnBox-leave-active {
  transition: opacity 0.7s;
  transition: transform 0.7s;
}
.returnBox-enter,
.returnBox-leave-to,
.installBox-enter,
.installBox-leave-to {
  transform: translateX(384px);
  opacity: 0;
}
.deviceBox-enter,
.deviceBox-leave-to,
.deviceWarnBox-enter,
.deviceWarnBox-leave-to,
.callWarnBox-enter,
.callWarnBox-leave-to,
.noticeBox-enter,
.noticeBox-leave-to {
  transform: translateY(384px);
  opacity: 0;
}
.callWarnBox-enter-active,
.callWarnBox-leave-active {
  transition: opacity 0.9s;
  transition: transform 0.9s;
}
.noticeBox-enter-active,
.noticeBox-leave-active {
  transition: opacity 1s;
  transition: transform 1s;
}
#mainChart {
  width: 380px;
  height: 220px;
}
</style>
