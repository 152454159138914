import request from '@/utils/request'

var  CODER_ALAW		= 0;
var  CODER_PCM		= 1;
var	 CODER_G729		= 3;
var	 CODER_SPEEX		= 20;
var	 CODER_ULAW		= 100;
var	 CODER_MP3		= 38;
var   UBOX_MODE_RECORD	= 0;		//录音模式， 通常使用的模式
var	  UBOX_MODE_DIAG		= 1;		//诊断模式， 用于捕获线路信息，供信号分析之用，支持的语音编码方式是CODER_PCM
var	  UBOX_MODE_CONFIG	= 2;		//配置模式，
var  hdl = -1;

var     UBOX_STATE_RESET     = 1;			//复位态，表示既非振铃也非摘机的状态。如果此前为振铃态，则此状态表示振铃已停止，如果此前为摘机态，则此状态表示已挂机。
var		UBOX_STATE_RINGING   = 2;			//振铃态，表示已检测到线路振铃信号，如果振铃停止，则将触发 UBOX_EVENT_RESET 事件，并汇报 UBOX_STATE_RESET 状态。
var		UBOX_STATE_HOOK_OFF  = 3;			//摘机态，
var		UBOX_STATE_HANG		 = 4;			  //悬空态，
var     UBOX_STATE_IDLE     = 5;              //空闲中，挂机
var		UBOX_STATE_REVERSE_HOOKOFF = 6;     //反向摘机，指软件摘机
var		UBOX_STATE_POSITIVIE_HOOKOFF = 7;   //正向摘机，指软件摘机

var      SLIC_TO_GSM	= 0;		//SLIC到GSM方向
var		 GSM_TO_SLIC = 1;	    //GSM到SLIC方向
var		EARPHONE_TO_GSM	= 2;	//
var		GSM_TO_EARPHONE =3;
var		SLIC_TO_EARPHONE = 4;
var		EARPHONE_TO_SLIC = 5;
var		USB_TO_EARPHONE = 6;
var		USB_TO_GSM = 7;
var		US_TO_SLIC = 8;

var   	SLIC_REC_	= 0;		//SLIC录音
var		GSM_REC = 1;	         //GSM录音
var		EARPHONE_REC	= 2;	//Earphone录音
var     USBPLAY_REC    = 3;     //录下电脑放音的声音
var     short_msg_number = 0;
var      EnabelUploadVoice = false;
var  ubox={                    //全局声明
            lines:[],
			linenum:0,
			workpath:"",
			callinMode:0,         //0:电话呼入手工模式 1：电话呼入自动接听，播放提示音
		    calloutMode:0,         //1:自动外呼模式
			tipfiles:[]
		 };
	
var  ws = null;	

var sprintf = function() {
    if (!arguments || arguments.length < 1) alert("sprintf:ERROR: not enough arguments");
    var argumentnum = 0;
    var done = "",
    todo = arguments[argumentnum++];
    var m;
    while (m = /^([^%]*)%(\d+$)?([#0 +'-]+)?(\*|\d+)?(\.\*|\.\d+)?([%diouxXfFcs])(.*)$/.exec(todo)) {
        var pProlog = m[1],
        pAccess = m[2],
        pFlags = m[3],
        pMinLength = m[4],
        pPrecision = m[5],
        pType = m[6],
        pEpilog = m[7];
        var subst;
        if (pType == '%') subst = '%';
        else {
            var padWith = ' ';
            var justifyRight = true;
            if (pFlags) {
                if (pFlags.indexOf('0') >= 0) padWith = '0';
                if (pFlags.indexOf('-') >= 0) {
                    padWith = ' ';
                    justifyRight = false;
                }
            } else pFlags = "";
            var minLength = -1;
            if (pMinLength) {
                if (pMinLength == "*") {
                    var access = argumentnum++;
                    if (access >= arguments.length) alert("sprintf:ERROR: not enough arguments");
                    minLength = arguments[access];
                } else minLength = parseInt(pMinLength, 10);
            }
            var precision = -1;
            if (pPrecision) {
                if (pPrecision == ".*") {
                    var access = argumentnum++;
                    if (access >= arguments.length) alert("sprintf:ERROR: not enough arguments");
                    precision = arguments[access];
                } else precision = parseInt(pPrecision.substring(1), 10);
            }
            var access = argumentnum++;
            if (pAccess) access = parseInt(pAccess.substring(0, pAccess.length - 1), 10);
            if (access >= arguments.length) alert("sprintf:ERROR: not enough arguments");
            var prefix = "";
            switch (pType) {
            case 'd':
            case 'i':
                subst = arguments[access];
                if (typeof subst != "number") subst = 0;
                subst = subst.toString(10);
                if (pFlags.indexOf('#') >= 0 && subst >= 0) subst = "+" + subst;
                if (pFlags.indexOf(' ') >= 0 && subst >= 0) subst = " " + subst;
                break;
            case 'o':
                subst = arguments[access];
                if (typeof subst != "number") subst = 0;
                subst = subst.toString(8);
                break;
            case 'u':
                subst = arguments[access];
                if (typeof subst != "number") subst = 0;
                subst = Math.abs(subst);
                subst = subst.toString(10);
                break;
            case 'x':
                subst = arguments[access];
                if (typeof subst != "number") subst = 0;
                subst = subst.toString(16).toLowerCase();
                if (pFlags.indexOf('#') >= 0) prefix = "0x";
                break;
            case 'X':
                subst = arguments[access];
                if (typeof subst != "number") subst = 0;
                subst = subst.toString(16).toUpperCase();
                if (pFlags.indexOf('#') >= 0) prefix = "0X";
                break;
            case 'f':
            case 'F':
                subst = arguments[access];
                if (typeof subst != "number") subst = 0.0;
                subst = 0.0 + subst;
                if (precision > -1) {
                    if (subst.toFixed) subst = subst.toFixed(precision);
                    else {
                        subst = (Math.round(subst * Math.pow(10, precision)) / Math.pow(10, precision));
                        subst += "0000000000";
                        subst = subst.substr(0, subst.indexOf(".") + precision + 1);
                    }
                }
                subst = '' + subst;
                if (pFlags.indexOf("'") >= 0) {
                    var k = 0;
                    for (var i = (subst.length - 1) - 3; i >= 0; i -= 3) {
                        subst = subst.substring(0, i) + (k == 0 ? ".": ",") + subst.substring(i);
                        k = (k + 1) % 2;
                    }
                }
                break;
            case 'c':
                subst = arguments[access];
                if (typeof subst != "number") subst = 0;
                subst = String.fromCharCode(subst);
                break;
            case 's':
                subst = arguments[access];
                if (precision > -1) subst = subst.substr(0, precision);
                if (typeof subst != "string") subst = "";
                break;
            }

            var padding = minLength - subst.toString().length - prefix.toString().length;
            if (padding > 0) {
                var arrTmp = new Array(padding + 1);
                if (justifyRight) subst = arrTmp.join(padWith) + subst;
                else subst = subst + arrTmp.join(padWith);
            }

            subst = prefix + subst;
        }

        done = done + pProlog + subst;
        todo = pEpilog;
    }
    return (done + todo);
}

// function console.log(szStatus)
// {
//    var  thisTime =new Date(); 

//     var time = thisTime.getHours()+":"+thisTime.getMinutes()+":"+thisTime.getSeconds()+"    ";
// 	document.getElementById("StatusArea").value +=time;
// 	document.getElementById("StatusArea").value +=szStatus;
// 	document.getElementById("StatusArea").value +="\r\n";
//     var scrollTop = $("#StatusArea")[0].scrollHeight;  
//     $("#StatusArea").scrollTop(scrollTop);  
// }

function  Get_Error(result)
{
       var error ="";
		switch( result)
		{
		case 0:
			error = "操作成功";	
			break;
		case -1:
			error = "系统错误";	
			break;
		case -2:
			error = "无此设备";	
			break;
		case -3:
			error = " 非法的 UBOX_HANDLE";	
			break;
		case -4:
			error = "非法参数";	
			break;
		case -10:
			error = "未能创建目录";	
			break;
		case -11:
			error = " 未能创建录音文件";	
			break;
		case -12:
			error = "不支持的语音编码";	
			break;
		case -6:
			error = "错误的工作模式";	
			break;
		case -5:
			error = "发生异常";	
			break;
		case -13:
			error = "设备忙";	
			break;
		case -7:
			error = " ubox设备尚未打开";	
			break;


		default:
			error = "未定义的错误码";	
			break;
		}
	return error;
}

function changelinenum(This)
{
	if(ubox.lines[This.value] == undefined)
	   alert("USB盒子未插入");
	 else{
	    hdl = ubox.lines[This.value].handle; 
		console.log("AAA",ubox.lines,hdl)
	 }

   
}	 

function Ubox_Plug_In(uboxhdl)
{
   
   var outdata = "设备插入 句柄号:"+uboxhdl;
   
   if(ubox.linenum == 2)      //不考虑三个通道
      return;
	  
   	ubox.linenum++;
   	if( ubox.lines[uboxhdl] == undefined ){
				ubox.lines[uboxhdl] = {};
			}

	ubox.lines[uboxhdl].handle = uboxhdl;
	ubox.lines[uboxhdl].callernumber = "";
    ubox.lines[uboxhdl].dtmfkyes = "";
	ubox.lines[uboxhdl].lineid = ubox.linenum;
	ubox.lines[uboxhdl].state =  UBOX_STATE_IDLE;
	ubox.lines[uboxhdl].record = 0;
	ubox.lines[uboxhdl].productid = 0;	
	if(ubox.linenum == 1)
	{
	    hdl = ubox.lines[uboxhdl].handle;
     	// document.getElementById("lineid1").disabled=false;
		// document.getElementById("lineid1").value=uboxhdl;
	}
	else
	{
     	// document.getElementById("lineid2").disabled=false;
		// document.getElementById("lineid2").value=uboxhdl;
	}
   	var msg = {
		   "event": "GetProductID",
		   "handle": uboxhdl
	};
		console.log("获取设备型号")
		/*
		如果失败， 在notify 消息中，会报错的
		*/
		websocket_send_msg(JSON.stringify(msg));
   console.log(outdata);

}

function Ubox_hookoff(uboxhdl)
{
  
   var outdata = "设备"+uboxhdl;
    ubox.lines[uboxhdl].state =  UBOX_STATE_HOOK_OFF;
  /* var rec_name = "";
	var curDate = new Date();
	var year = curDate.getFullYear();
	var month = curDate.getMonth()+1;
	var day = curDate.getDate();
	var hours= curDate.getHours();
	var minutes = curDate.getMinutes();
	var seconds = curDate.getSeconds();
    if(ubox.lines[uboxhdl].state ==  UBOX_STATE_RINGING)
     {
		console.log(outdata+" 呼入摘机");
		rec_name = "D:\\record\\IN_"+year+"-"+month+"-"+day+"-"+hours+"-"+minutes+"-"+seconds+".wav";
	 }
	else
	{
	   console.log(outdata+" 呼出摘机");  
	   rec_name = "D:\\record\\OUT_"+year+"-"+month+"-"+day+"-"+hours+"-"+minutes+"-"+seconds+".wav";
	}
	   
	 ubox.lines[uboxhdl].state =  UBOX_STATE_HOOK_OFF;
	if(ubox.lines[uboxhdl].record == 0)
	{
		var msg = {
			   "event": "RecordFile",
			   "handle": uboxhdl,
			   "filename":rec_name,
			   "codec":  CODER_ALAW
			};
			console.log("开始录音...")
			
			如果失败， 在notify 消息中，会报错的
		
		  websocket_send_msg(JSON.stringify(msg));
		  ubox.lines[uboxhdl].record = 1;
	  }
	 */
}

function Ubox_hookon(uboxhdl)
{
	//TODO  对方挂机和自己挂机
   var outdata = "设备"+uboxhdl;
	ubox.lines[uboxhdl].state =  UBOX_STATE_IDLE;
    console.log(outdata+" 挂机"); //设备3073 挂机
	// window.cp.createCallDt({type:2}).show();//type:0 接听；1：通话中；2：挂断

	ubox.lines[uboxhdl].record = 0;

	if(hdl != -1)
	{
		var msg = {
		   "event": "StopRecord",
		   "handle": uboxhdl
		};
	   /*
		如果失败， 在notify 消息中，会报错的
		*/
		websocket_send_msg(JSON.stringify(msg));
		console.log("已经停止录音");
		// var file = {
		// 	uri:rec_nameGlobal,
		// 	type:'wav',
		// 	name:fileNameGlobal,
		// 	size:getfilesize(rec_nameGlobal) || 110
		// }
		// var formData = new FormData()
		// // formData.append('file',file)
		// console.log("file:::",file)
		// console.log("formData:::",formData)
		// var postDt = toFormData({
		// 	file0
		// uploadFileVoice(fileNameGlobal,postDt)		
	}

}

var callinNum = null
function Ubox_CallId(uboxHandle,callerNumber)
{
  
	//TODO  来电号码
   var outdata = "设备"+uboxHandle+"号码："+callerNumber ;
   callinNum = callerNumber
    console.log(outdata);
	const num = callerNumber.length == 13 ? callerNumber.replace('86','') : callerNumber
	// window.cp.createCallDt().hide()
	window.cp.getPhoneInfo(callerNumber).then((res)=>{
		console.log("查询来电号码信息：：",res)
		if(!res.success) {  //会员电话详情弹窗
			if(res.data){
				window.cp.createCallDt({userInfo:res.data,type:0,phone:num}).show();//type:0 接听；1：通话中；2：挂断  
			}else{
				window.cp.createCall({phone:num,callType:0}).show()
			}
		}else{  //普通来电弹窗
			window.cp.createCall({phone:num,callType:0}).show()
		}
	})
	if((ubox.lines[uboxHandle].productid == 1)||(ubox.lines[uboxHandle].productid == 4)||(ubox.lines[uboxHandle].productid == 10))
	{
	   if(ubox.callinMode)
	    {
           {
			var msg = {
			   "event": "SoftHookoff",
			   "handle": uboxHandle
			};
			console.log("软件摘机:");
			/*
			如果失败， 在notify 消息中，会报错的
			*/
			websocket_send_msg(JSON.stringify(msg));	
           ubox.lines[uboxHandle].state =  UBOX_STATE_HOOK_OFF;
          }	
		    if(ubox.tipfiles.length >0)
			{
			  var  path = ubox.workpath + "customvoices\\"+ubox.tipfiles[0].filename;
				console.log(path+",检查该文件是否存在！！！！");
				if(hdl != -1)
				{
					var msg = {
					   "event": "PlayFile",
					   "handle": uboxHandle,
					   "ratio":  0,
					   "filename":path
					};
					console.log("开始放音...")
					/*
					如果失败， 在notify 消息中，会报错的
					*/
					websocket_send_msg(JSON.stringify(msg));
				}
			}		  
		}
	}

}


function ubox_Ring(uboxhdl)
{
   var outdata = "设备"+uboxhdl;
	//TODO
    console.log(outdata+" 振铃11");
	ubox.lines[uboxhdl].state =  UBOX_STATE_RINGING;
}

function ubox_RingCancel(uboxhdl)
{
   var outdata = "设备"+uboxhdl;
  	ubox.lines[uboxhdl].state =  UBOX_STATE_IDLE;
	//TODO
    console.log(outdata+" 振铃取消");
	window.cp.createCall().hide()
	window.cp.createCallDt().hide()
	window.cp.getPhoneInfo(callinNum).then((res)=>{
		console.log("查询来电号码信息：：",res)
		if(res.success) {  //会员电话详情弹窗
			window.cp.createCallDt({userInfo:res.data,type:2,phone:callinNum}).show();//type:0 接听；1：通话中；2：未接来电
		}else{  //普通来电弹窗
			window.cp.createCall({phone:callinNum,callType:3}).show()
		}
	})	


}

function ubox_RingStop(uboxhdl)
{
   var outdata = "设备"+uboxhdl;

    console.log(outdata+" 停振");

}

function ubox_ToneBusy(uboxhdl)
{
   var outdata = "设备"+uboxhdl;

    console.log(outdata+" 忙音");

}

function ubox_DeviceAlarm(uboxhdl, alarmType, alarmParam) {
	var outdata = "设备" + uboxhdl;

	if (alarmType == 2) //未能打开 ubox 的 MIC 设备
	{
		switch (alarmParam) {
			case 1:
				{
					console.log(outdata + " 不能打开USB mic设备,系统级错误,收不到来电号码和不能录音，USB线重新插拔一下，重启录音软件。如果重启后，还有此提示，处理方法参考用户手册");
				}
				break;
			case 2:
				{
					console.log(outdata + " windows audio服务没有启动或者USB Audio devcie驱动没有安装好,收不到来电号码和不能录音，USB线重新插拔一下，重启录音软件。如果重启后，还有此提示，处理方法参考用户手册");
				}
				break;
			case 3:
				{
					console.log(outdata + " USB mic设备被禁用了或者驱动没有安装好,收不到来电号码和不能录音，USB线重新插拔一下，重启录音软件。如果重启后，还有此提示，处理方法参考用户手册");
				}
				break;
			case 4:
				{
					console.log(outdata + " USB Audio devcie驱动没有安装好或者USB play和mic设备都被禁用了,收不到来电号码和不能录音，USB线重新插拔一下，重启录音软件。如果重启后，还有此提示，处理方法参考用户手册");
				}
				break;
			case 5:
				{
					console.log(outdata + " USB mic设备都被禁用了,收不到来电号码和不能录音，USB线重新插拔一下，重启录音软件。如果重启后，还有此提示，处理方法参考用户手册");
				}
				break;
			case 8:
			case 9:
				{
					console.log(outdata + " 不支持共享模式和独占模式,收不到来电号码和不能录音，USB线重新插拔一下，重启录音软件。如果重启后，还有此提示，处理方法参考用户手册");
				}
				break;
			case 10:
				{
					console.log(outdata + " 初始化USB mic失败，被另外一个软件独占USB mic了，导致不能打开,收不到来电号码和不能录音，USB线重新插拔一下，重启录音软件。如果重启后，还有此提示，处理方法参考用户手册");
				}
				break;
			case 11:
				{
					console.log(outdata + " 被拒绝访问,没有访问权限，检查mic隐私是否打开,收不到来电号码和不能录音，处理方法参考用户手册");
				}
				break;
			default:
				{
					console.log(outdata + " 不能打开USB mic设备,其它系统错误,收不到来电号码和不能录音，USB线重新插拔一下，重启录音软件。如果重启后，还有此提示，处理方法参考用户手册");
				}
				break;
		}
	}
	else if (alarmType == 3) //未能打开 ubox 的 放音 设备
	{
		switch (alarmParam) {
			case 1:
				{
					console.log(outdata + " 打开USB play设备有错误，系统级错误,不能拨号和放音，USB线重新插拔一下，重启录音软件。如果重启后，还有此提示，处理方法参考用户手册");
				}
				break;
			case 2:
				{
					console.log(outdata + " windows audio 服务没有启动或USB Audio devcie驱动没有安装好,不能拨号和放音，USB线重新插拔一下，重启录音软件。如果重启后，还有此提示，处理方法参考用户手册");
				}
				break;
			case 3:
				{
					console.log(outdata + " USB Audio devcie驱动没有安装好或USB play 和mic设备都被禁用了,不能拨号和放音，USB线重新插拔一下，重启录音软件。如果重启后，还有此提示，处理方法参考用户手册");
				}
				break;
			case 4:
				{
					console.log(outdata + " 没有usb play设备，被禁用了,不能拨号和放音，USB线重新插拔一下，重启录音软件。如果重启后，还有此提示，处理方法参考用户手册");
				}
				break;
			case 5:
				{
					console.log(outdata + " USB音频设备被禁用了或者驱动没有安装好,不能拨号和放音，USB线重新插拔一下，重启录音软件。如果重启后，还有此提示，处理方法参考用户手册");
				}
				break;
			default:
				{
					console.log(outdata + " 不能打开USB play设备,其它系统错误,不能拨号和放音，USB线重新插拔一下，重启录音软件。如果重启后，还有此提示，处理方法参考用户手册");
				}
				break;
		}
	}
	else if (alarmType == 5) //复位 audio device失败
	{
        console.log(outdata + " 设备警告，运行中出现设备不能录音，换一个USB端口插入USB盒子");
	}
	else   //其它错误
	{
		console.log(outdata + " 设备警告，不能打开USB mic设备,收不到来电号码和不能录音，USB线重新插拔一下，重启录音软件。如果重启后，还有此提示，处理方法参考用户手册");
	}


}

function ubox_DeviceError(uboxhdl)
{
   var outdata = "设备"+uboxhdl;

    console.log(outdata+" 错误，重启软件");

}

function ubox_DtmfKey(uboxHandle,dtmfCode)
{

   var outdata = "设备"+uboxHandle+"按键:"+(dtmfCode-48);

    console.log(outdata);

}

function ubox_HangIng(uboxhdl)
{
   var outdata = "设备"+uboxhdl;

    console.log(outdata+" 电话未连接好");
   if( ubox.lines[uboxhdl].record  == 1)
   {
			ubox.lines[uboxhdl].record = 0;
			if(hdl != -1)
			{
				var msg = {
				   "event": "StopRecord",
				   "handle": uboxhdl
				};
			   /*
				如果失败， 在notify 消息中，会报错的
				*/
				websocket_send_msg(JSON.stringify(msg));
				console.log("已经停止录音");
			}
   }
}

function ubox_LineVoltage(uboxhdl,voltage)
{
   var outdata = "设备"+uboxhdl+"线路电压:"+voltage;

    console.log(outdata);

}

function ubox_PlayEnd(uboxhdl)
{
   var outdata = "设备"+uboxhdl;

    console.log(outdata+" 放音结束");
	if(ubox.callinMode)
	{
		var msg = {
		   "event": "SoftHookon",
		   "handle": uboxhdl
		};
		console.log("软件挂机")
		/*
		如果失败， 在notify 消息中，会报错的
		*/
		websocket_send_msg(JSON.stringify(msg));	
	}

}

function ubox_PlugOut(uboxhdl)
{
   var outdata = "设备"+uboxhdl;
   
  /* for(int i=1;i<=ubox.linenum;i++)
   {
       if(ubox.lines[i]==undefine)
   }*/
  ubox.lines[uboxhdl].handle = -1;
  ubox.lines[uboxhdl] = undefined;
  ubox.linenum -= 1;
  console.log(outdata+" 拨出");
  hdl=-1;

}

function ubox_PlayError(uboxhdl)
{
   var outdata = "设备"+uboxhdl;

    console.log(outdata+" 放音错误");

}

/*
function ubox_openDevice()
{

 try{
    //  Phonic_usb.OpenLogFile(0);
	Phonic_usb.OpenLogFileEx("D:\\logs");
	  console.log("open log debug2");
 	var uChannelNum=Phonic_usb.OpenDevice(0);
 	if(uChannelNum == 0)
 	{
 		console.log("已经初始化设备成功");

 	}
	else
 	{
	 	console.log("打开设备失败");		
 	}
 }catch(e)
 {
 }

}

function ubox_CloseDevice()
{

	Phonic_usb.CloseDevice();
	console.log("关闭设备完成.");
	Phonic_usb.CloseLogFile();

}
*/


function setrecordenable()
{
	//alert($('#slicrecEnable').val());
    if(hdl != -1)
	{
		var msg = {
		   "event": "set_record_enable",
		   "handle": hdl,
		   "mode": 0,
		   "value":0
		};
		msg.mode = SLIC_REC_;
		if ($('#slicrecEnable').prop("checked"))
		{
				//alert("选中");
		    msg.value = 1;
		} 
		else 
		{
				//alert("没有选中");
			msg.value = 0;
		}	
		websocket_send_msg(JSON.stringify(msg));
		
		msg.mode = GSM_REC;
		if ($('#gsmrecEnable').prop("checked"))
		{
				//alert("选中");
		    msg.value = 1;
		} 
		else 
		{
				//alert("没有选中");
			msg.value = 0;
		}	
		websocket_send_msg(JSON.stringify(msg));
		
		msg.mode = EARPHONE_REC;
		if ($('#earphonerecEnable').prop("checked"))
		{
				//alert("选中");
		    msg.value = 1;
		} 
		else 
		{
				//alert("没有选中");
			msg.value = 0;
		}	
		websocket_send_msg(JSON.stringify(msg));
		
		msg.mode = USBPLAY_REC;
		if ($('#usbplayrecEnable').prop("checked"))
		{
				//alert("选中");
		    msg.value = 1;
		} 
		else 
		{
				//alert("没有选中");
			msg.value = 0;
		}	
		websocket_send_msg(JSON.stringify(msg));
		
        console.log("设置录音方向开关");
	}

	
}

function settalkEnable()
{
	if(hdl != -1)
	{
		var msg = {
		   "event": "set_talk_link",
		   "handle": hdl,
		   "mode": 0,
		   "value":0
		};
		msg.mode = SLIC_TO_GSM;
		if ($('#sliToGsm').prop("checked"))
		{
				//alert("选中");
		    msg.value = 1;
		} 
		else 
		{
				//alert("没有选中");
			msg.value = 0;
		}	
		websocket_send_msg(JSON.stringify(msg));
		
		msg.mode = GSM_TO_SLIC;
		if ($('#GsmToslic').prop("checked"))
		{
				//alert("选中");
		    msg.value = 1;
		} 
		else 
		{
				//alert("没有选中");
			msg.value = 0;
		}	
		websocket_send_msg(JSON.stringify(msg));
		
		msg.mode = EARPHONE_TO_GSM;
		if ($('#earphoneToGsm').prop("checked"))
		{
				//alert("选中");
		    msg.value = 1;
		} 
		else 
		{
				//alert("没有选中");
			msg.value = 0;
		}	
		websocket_send_msg(JSON.stringify(msg));
		
		msg.mode = GSM_TO_EARPHONE;
		if ($('#GsmToearphone').prop("checked"))
		{
				//alert("选中");
		    msg.value = 1;
		} 
		else 
		{
				//alert("没有选中");
			msg.value = 0;
		}	
		websocket_send_msg(JSON.stringify(msg));
		
		
		msg.mode = USB_TO_EARPHONE;
		if ($('#usbToearphone').prop("checked"))
		{
				//alert("选中");
		    msg.value = 1;
		} 
		else 
		{
				//alert("没有选中");
			msg.value = 0;
		}	
		websocket_send_msg(JSON.stringify(msg));
		
		msg.mode = USB_TO_GSM;
		if ($('#usbToGsm').prop("checked"))
		{
				//alert("选中");
		    msg.value = 1;
		} 
		else 
		{
				//alert("没有选中");
			msg.value = 0;
		}	
		websocket_send_msg(JSON.stringify(msg));
		
		msg.mode = US_TO_SLIC;
		if ($('#usbToslic').prop("checked"))
		{
				//alert("选中");
		    msg.value = 1;
		} 
		else 
		{
				//alert("没有选中");
			msg.value = 0;
		}	
		websocket_send_msg(JSON.stringify(msg));


        console.log("设置通话方向开关")
	   /*
		如果失败， 在notify 消息中，会报错的
		*/
		
		//console.log("已经停止录音");
	}

}

function set3101b_recordenable()
{
	//alert($('#slicrecEnable').val());
    if(hdl != -1)
	{
		var msg = {
		   "event": "set_record_enable",
		   "handle": hdl,
		   "mode": 0,
		   "value":0
		};
		msg.mode = SLIC_REC_;
		if ($('#3101bslicrecEnable').prop("checked"))
		{
				//alert("选中");
		    msg.value = 1;
		} 
		else 
		{
				//alert("没有选中");
			msg.value = 0;
		}	
		websocket_send_msg(JSON.stringify(msg));
		
		msg.mode = GSM_REC;
		if ($('#3101bgsmrecEnable').prop("checked"))
		{
				//alert("选中");
		    msg.value = 1;
		} 
		else 
		{
				//alert("没有选中");
			msg.value = 0;
		}	
		websocket_send_msg(JSON.stringify(msg));
		
		msg.mode = EARPHONE_REC;
		if ($('#3101bearphonerecEnable').prop("checked"))
		{
				//alert("选中");
		    msg.value = 1;
		} 
		else 
		{
				//alert("没有选中");
			msg.value = 0;
		}	
		websocket_send_msg(JSON.stringify(msg));
		
		msg.mode = USBPLAY_REC;
		if ($('#3101busbplayrecEnable').prop("checked"))
		{
				//alert("选中");
		    msg.value = 1;
		} 
		else 
		{
				//alert("没有选中");
			msg.value = 0;
		}	
		websocket_send_msg(JSON.stringify(msg));
		
        console.log("设置录音方向开关");
	}

	
}


function set3101b_talkEnable()
{
	if(hdl != -1)
	{
		var msg = {
		   "event": "set_talk_link",
		   "handle": hdl,
		   "mode": 0,
		   "value":0
		};
		msg.mode = SLIC_TO_GSM;
		if ($('#3101bSliToExt').prop("checked"))
		{
				//alert("选中");
		    msg.value = 1;
		} 
		else 
		{
				//alert("没有选中");
			msg.value = 0;
		}	
		websocket_send_msg(JSON.stringify(msg));
		
		msg.mode = GSM_TO_SLIC;
		if ($('#3101bExtToslic').prop("checked"))
		{
				//alert("选中");
		    msg.value = 1;
		} 
		else 
		{
				//alert("没有选中");
			msg.value = 0;
		}	
		websocket_send_msg(JSON.stringify(msg));
		
		msg.mode = EARPHONE_TO_GSM;
		if ($('#3101bearphoneToExt').prop("checked"))
		{
				//alert("选中");
		    msg.value = 1;
		} 
		else 
		{
				//alert("没有选中");
			msg.value = 0;
		}	
		websocket_send_msg(JSON.stringify(msg));
		
		msg.mode = GSM_TO_EARPHONE;
		if ($('#3101bExtToearphone').prop("checked"))
		{
				//alert("选中");
		    msg.value = 1;
		} 
		else 
		{
				//alert("没有选中");
			msg.value = 0;
		}	
		websocket_send_msg(JSON.stringify(msg));
		
		
		msg.mode = USB_TO_EARPHONE;
		if ($('#3101busbToearphone').prop("checked"))
		{
				//alert("选中");
		    msg.value = 1;
		} 
		else 
		{
				//alert("没有选中");
			msg.value = 0;
		}	
		websocket_send_msg(JSON.stringify(msg));
		
		msg.mode = USB_TO_GSM;
		if ($('#3101busbToExt').prop("checked"))
		{
				//alert("选中");
		    msg.value = 1;
		} 
		else 
		{
				//alert("没有选中");
			msg.value = 0;
		}	
		websocket_send_msg(JSON.stringify(msg));
		
		msg.mode = US_TO_SLIC;
		if ($('#3101busbToslic').prop("checked"))
		{
				//alert("选中");
		    msg.value = 1;
		} 
		else 
		{
				//alert("没有选中");
			msg.value = 0;
		}	
		websocket_send_msg(JSON.stringify(msg));


        console.log("设置通话方向开关")
	   /*
		如果失败， 在notify 消息中，会报错的
		*/
		
		//console.log("已经停止录音");
	}

}
var rec_nameGlobal = null
var fileNameGlobal = null
//上传录音文件
function uploadFileVoice(fileName,data) {
	console.log(data)
    return request({
        url: 'property/callWarning/callMediaAdd/file/'+fileName,
        method: 'POST',
        data:data
    })
}

function toFormData (obj) {
	var formData = new FormData()
	for(let key in obj) {
		formData.append(key,obj[key])
	}
	return formData
}
function ubox_RecordFile()
{
	var curDate = new Date();
	var year = curDate.getFullYear();
	var month = curDate.getMonth()+1;
	var day = curDate.getDate();
	var hours= curDate.getHours();
	var minutes = curDate.getMinutes();
	var seconds = curDate.getSeconds();

	var rec_name = ubox.workpath+"ubox_record\\"+year+"-"+month+"-"+day+"-"+hours+"-"+minutes+"-"+seconds+".wav";
	var fileName = year+"-"+month+"-"+day+"-"+hours+"-"+minutes+"-"+seconds+".wav"
	rec_nameGlobal = rec_name
	fileNameGlobal = fileName
	//var rec_name = "E:\\usb\\gsm-usb\\bin\\ubox-record\\test.wav";

	//TODO 文件路径
	console.log(rec_name); 
	//var msg = [];
	if(hdl != -1)
	{
		var msg = {
		   "event": "RecordFile",
		   "handle": hdl,
		   "filename":rec_name,
		   "codec":  CODER_PCM
		};
		console.log("开始录音...")
		
		//如果失败， 在notify 消息中，会报错的
		
		websocket_send_msg(JSON.stringify(msg));
	}
	
	//Fi3101B
	if(ubox.lines[hdl].productid == 10)  //外线通道录音
	{
	   rec_name = ubox.workpath+"ubox_record\\"+year+"-"+month+"-"+day+"-"+hours+"-"+minutes+"-"+seconds+"-ext.wav";
		//var rec_name = "E:\\usb\\gsm-usb\\bin\\ubox-record\\test.wav";
		console.log(rec_name);
		//var msg = [];
		if(hdl != -1)
		{
			var msg = {
			   "event": "RecordFile",
			   "handle": hdl+1,
			   "filename":rec_name,
			   "codec":  CODER_PCM
			};
			console.log("外线开始录音...")
			
			//如果失败， 在notify 消息中，会报错的
			
			websocket_send_msg(JSON.stringify(msg));
		}
	}
			

}

function ubox_StopRecord()
{
	if(hdl != -1)
	{
		var msg = {
		   "event": "StopRecord",
		   "handle": hdl
		};
	  
		//  如果失败， 在notify 消息中，会报错的
		
		websocket_send_msg(JSON.stringify(msg));
		console.log("已经停止录音");
	    //Fi3101B
		if(ubox.lines[hdl].productid == 10)  //外线通道录音
		{
		   msg = {
		   "event": "StopRecord",
		   "handle": hdl+1
		   };
	  
		//  如果失败， 在notify 消息中，会报错的
		
		   websocket_send_msg(JSON.stringify(msg));
		   console.log("外线通道停止录音");			
		}
	}
}


function ubox_playfile(ratio)
{
   //alert("szFile:"+get_filepath(document.getElementById('filename')));
   var  filename = ubox.workpath + "tip.wav";
	console.log(filename+",检查该文件是否存在！！！！"+",ratio:"+ratio);
	if(hdl != -1)
	{
		var msg = {
		   "event": "PlayFile",
		   "handle": hdl,
		   "ratio":  parseInt(ratio),
		   "filename":filename
		};
		console.log("开始放音...")
		/*
		如果失败， 在notify 消息中，会报错的
		*/
		websocket_send_msg(JSON.stringify(msg));
	}
}

function ubox_stopplay()
{
	if(hdl != -1)
	{
		var msg = {
		   "event": "StopPlay",
		   "handle": hdl
		};
		console.log("停止放音")
		/*
		如果失败， 在notify 消息中，会报错的
		*/
		websocket_send_msg(JSON.stringify(msg));
	}
}




function ubox_dialnum(szCode)
{
	if(typeof szCode == 'number') {
		szCode = JSON.stringify(szCode)
	}
	console.log('电话号码类型::',typeof szCode,'hdl:::',hdl)
  	if(hdl != -1)
	{
	    if((ubox.lines[hdl].productid == 1)||(ubox.lines[hdl].productid == 4)||(ubox.lines[hdl].productid == 10))
		{
			var msg = {
			   "event": "SoftHookoff",
			   "handle": hdl
			};
			console.log("软件摘机:"+JSON.stringify(msg));
			// window.cp.createCall({phone:szCode,callType:1}).show()   //打开 正在呼叫弹窗
			/*
			如果失败， 在notify 消息中，会报错的
			*/
			websocket_send_msg(JSON.stringify(msg));	
          ubox.lines[hdl].state =  UBOX_STATE_HOOK_OFF;	
         setTimeout(function(){
		  if(	 ubox.lines[hdl].state ==  UBOX_STATE_HOOK_OFF)
		  {
								var msg = {
							   "event": "SendDtmf",
							   "handle": hdl,
							   "dtmf":szCode
							};
							console.log("拨号:"+szCode);
							/*
							如果失败， 在notify 消息中，会报错的
							*/
							websocket_send_msg(JSON.stringify(msg));		
						}
						else
						{
						   console.log("请摘起电话机");
					 }
		            
				},1000);		  
		}
		else if(ubox.lines[hdl].productid == 8)  //无线设备
		{
		    if(ubox.lines[hdl].state ==  UBOX_STATE_HOOK_OFF)
			{
					var msg = {
				   "event": "SendDtmf",
				   "handle": hdl,
				   "dtmf":szCode
				};
				console.log("通话中发送按键:"+szCode);
				/*
				如果失败， 在notify 消息中，会报错的
				*/
				websocket_send_msg(JSON.stringify(msg));
			}
			else
			{
				var msg = {
				   "event": "ubox_makecall",
				   "handle": hdl,
				   "dtmf":szCode
				};
				console.log("软件拨号:"+szCode);
			  websocket_send_msg(JSON.stringify(msg));	
			}
		}
		else
		{
			if(	 ubox.lines[hdl].state ==  UBOX_STATE_HOOK_OFF)
			{
					var msg = {
				   "event": "SendDtmf",
				   "handle": hdl,
				   "dtmf":szCode
				};
				console.log("拨号:"+szCode);
				/*
				如果失败， 在notify 消息中，会报错的
				*/
				websocket_send_msg(JSON.stringify(msg));		
			}
			else
			   console.log("请摘起电话机");
		}
	 }
}


function ubox_Start_read_line_voltage()
{
	if(hdl != -1)
	{
		var msg = {
		   "event": "StartReadLineVoltage",
		   "handle": hdl
		};
		console.log("开始量线路电压");
		/*
		如果失败， 在notify 消息中，会报错的
		*/
		websocket_send_msg(JSON.stringify(msg));
	}
}

function ubox_Stop_read_line_voltage()
{
/*
  if(Phonic_usb.StopReadLineVoltage(hdl) == 0)
     console.log("停止量线路电压成功");
  else
     console.log("停止量线路电压失败");
	 */
	if(hdl != -1)
	{
		var msg = {
		   "event": "StopReadLineVoltage",
		   "handle": hdl
		};
		console.log("停止量线路电压");
		/*
		如果失败， 在notify 消息中，会报错的
		*/
		websocket_send_msg(JSON.stringify(msg));
	}
}

function ubox_Get_DeviceVersion()
{
 // var ver = Phonic_usb.GetDeviceVersionNum(hdl);
  //   console.log("硬件版本号"+ver);
 	if(hdl != -1)
	{
		var msg = {
		   "event": "GetDeviceVersionNum",
		   "handle": hdl
		};
		console.log("获取硬件版本号");
		/*
		如果失败， 在notify 消息中，会报错的
		*/
		websocket_send_msg(JSON.stringify(msg));
	}

}

function ubox_Get_User()
{
  //var use_num = Phonic_usb.GetUsernum(hdl);
  //   console.log("用户号:"+use_num);
   	if(hdl != -1)
	{
		var msg = {
		   "event": "GetUsernum",
		   "handle": hdl
		};
		console.log("用户号:");
		/*
		如果失败， 在notify 消息中，会报错的
		*/
		websocket_send_msg(JSON.stringify(msg));
	}
}

function ubox_read_eeprom()
{
 //  var read_data = Phonic_usb.ReadEepromex2(hdl,0,8);
  //   console.log("读eepromEx:"+read_data);

}


function ubox_write_eeprom()
{
/*
    var write_data = "A23456B9";
	if(Phonic_usb.WriteEepromex(hdl,0,write_data,8)==0)
	{
		console.log("写eeprom成功");
	}
	else
	{
      console.log("写eeprom失败");
	}
	*/
}

function ubox_hook_off()
{

         //fi3102A fi3101A 支持
/*
	  if(Phonic_usb.SoftHookoff(hdl)==0)
       {
		console.log("摘机成功");
       }
	   else
	   {
         console.log("摘机失败");
	   }
*/
   	if(hdl != -1)
	{
		var msg = {
		   "event": "SoftHookoff",
		   "handle": hdl
		};
		console.log("软件摘机:");
		/*
		如果失败， 在notify 消息中，会报错的
		*/
		websocket_send_msg(JSON.stringify(msg));
	}
}

function ubox_hook_on()
{
        //fi3102A fi3101A 支持
		/*
 	if(Phonic_usb.SoftHookon(hdl)==0)
       {
		console.log("挂机成功");
       }
	   else
	   {
         console.log("挂机失败");
	   }
	*/
   	if(hdl != -1)
	{
		var msg = {
		   "event": "SoftHookon",
		   "handle": hdl
		};
		//TODO
		console.log("软件挂机")
		window.cp.createCall().hide()
		/*
		如果失败， 在notify 消息中，会报错的
		*/
		websocket_send_msg(JSON.stringify(msg));
	}
}

function ubox_reject_on()
{
   	if(hdl != -1)
	{
		if(ubox.lines[hdl].productid == 8)  //无线设备
		{
			var msg = {
			   "event": "rejectcall",
			   "handle": hdl
			};
			console.log("拒接:");
			/*
			如果失败， 在notify 消息中，会报错的
			*/
			websocket_send_msg(JSON.stringify(msg));
		}
		else
		{
			console.log("型号不支持该功能");
		}
	}
}

function ubox_makecallSlic(state)
{
   	if(hdl != -1)
	{
		if((ubox.lines[hdl].productid == 8) || (ubox.lines[hdl].productid == 10))  //无线设备
		{
			var msg = {
			   "event": "ubox_makecallSlic",
			   "handle": hdl,
			   "state":state,       //state:1振铃， 0：停振铃
			   "phonenumber":"91234567890"
			};
			console.log("slic 振铃");
			/*
			如果失败， 在notify 消息中，会报错的
			*/
			websocket_send_msg(JSON.stringify(msg));
		}
		else
		{
			console.log("型号不支持该功能");
		}
	}
}

function gsm_sendcmd(cmd)
{
	if(hdl != -1)
	{
		if(ubox.lines[hdl].productid == 8)  //无线设备
		{
			var msg = {
			   "event": "sendcmd",
			   "handle": hdl,
			   "cmd":cmd
			};
			console.log("发送cmd:"+cmd);
			/*
			如果失败， 在notify 消息中，会报错的
			*/
			websocket_send_msg(JSON.stringify(msg));
		}
		else
		{
			console.log("型号不支持该功能");
		}
	}
}

/*
function ubox_handle_streamVoice(uboxHandle, pVoice, size)
{
	 var outdata = "流式录音 句柄号:"+hdl +"size:"+size;
   console.log(outdata);
}
*/

function getfilesize(filename)
{

    // var  filename = "E:\\usb\\usb开发例子\\ubox-websocket-server\\bin\\test.wav";
	if(hdl != -1)
 		var msg = {
		   "event": "JudgeFileSize",
		   "filename":filename
		};
		console.log("获取文件大小：")
		/*
		如果失败， 在notify 消息中，会报错的
		*/
		websocket_send_msg(JSON.stringify(msg));
		
}

function UBOXDeletefile(filename)
{

    var  filename = "E:\\usb\\usb开发例子\\ubox-websocket-server\\bin\\test.wav";
 		var msg = {
		   "event": "UBOXDeletefile",
		   "filename":filename
		};
		console.log("delete file")
		/*
		如果失败， 在notify 消息中，会报错的
		*/
		websocket_send_msg(JSON.stringify(msg));
	
}

function UploadfileToserver(url, paramname, filename)
{

    var  filename = "E:\\usb\\usb开发例子\\ubox-websocket-server\\bin\\test.wav";
 		var msg = {
		   "event": "UploadFile",
		   "url":url,
		   "paramname":paramname,
		   "filename":filename
		   
		};
		console.log("上传文件...")
		/*
		如果失败， 在notify 消息中，会报错的
		*/
		websocket_send_msg(JSON.stringify(msg));
}

function UploadfileToserverEx(url, paramname, filename)
{

    var  filename = "E:\\usb\\usb开发例子\\ubox-websocket-server\\bin\\test.wav";
 		var msg = {
		   "event": "UploadFileEx",
		   "url":url,
		   "paramname":paramname,
		   "filename":filename
		   
		};
		console.log("上传文件...")
		/*
		如果失败， 在notify 消息中，会报错的
		*/
		websocket_send_msg(JSON.stringify(msg));

}

function UploadFormFile()
{
    var url = $('#formUrl').val();
	var  bucketName =  $('#UerId').val();
    var  filename = "E:\\test.wav";
 		var msg = {
		   "event": "UploadFormFile",
		   "uploadurl":url,
		   "bucketName":bucketName,
		   "objectName":"test.wav",
		   "localfilepath":filename
		   
		};
		console.log("upload formFile file")
		/*
		返回结果在 uploadfileresult
		*/
		websocket_send_msg(JSON.stringify(msg));

}

function  ubox_get_systeminfo()
{
		var msg = {
		   "event": "getsysteminfo"
		};
		console.log("获取系统信息")
		/*
		如果失败， 在notify 消息中，会报错的
		*/
		websocket_send_msg(JSON.stringify(msg));
}

function ubox_get_productid()
{
   	if(hdl != -1)
	{
		var msg = {
		   "event": "GetProductID",
		   "handle": hdl
		};
		console.log("获取型号")
		/*
		如果失败， 在notify 消息中，会报错的
		*/
		websocket_send_msg(JSON.stringify(msg));
	}	
}

function sleep(d){
  for(var t = Date.now();Date.now() - t <= d;);
}

function changesimcard(This)
{
	var simno = 1;
	if(hdl != -1)
	{
		if(This.value == 1)
		{
			//alert("select sim card 1");
			simno = 1;
		}
		 else
		 {
			 //alert("select sim card 2");
			 simno = 2;
		 }
		if(ubox.lines[hdl].productid == 8)  //无线设备
		{
			var msg = {
			   "event": "selectsimcard",
			   "handle": hdl,
			   "cardno":simno-1
			};
			console.log("choose card number:"+simno);
			/*
			如果失败， 在notify 消息中，会报错的
			*/
			websocket_send_msg(JSON.stringify(msg));
			
			var msg = {
			   "event": "gsmpower",
			   "handle": hdl,
			   "value":0
			};
			//sleep(2000);
			websocket_send_msg(JSON.stringify(msg));
			sleep(2000);
			var msg = {
			   "event": "gsmpower",
			   "handle": hdl,
			   "value":1
			};
			websocket_send_msg(JSON.stringify(msg));
			sleep(2000);
				var msg = {
			   "event": "gsmboot",
			   "handle": hdl,
			   "value":1
			};
			websocket_send_msg(JSON.stringify(msg));
            sleep(2000);
				var msg = {
			   "event": "gsmboot",
			   "handle": hdl,
			   "value":0
			};
			websocket_send_msg(JSON.stringify(msg));			
		}
		else
		{
			console.log("型号不支持该功能");
		}
	}	
   
}	

function FastSelectSimCard(This)
{
var simno = 1;
	if(hdl != -1)
	{
		if(This.value == 1)
		{
			//alert("select sim card 1");
			simno = 1;
		}
		 else
		 {
			 //alert("select sim card 2");
			 simno = 2;
		 }
		if(ubox.lines[hdl].productid == 8)  //无线设备
		{
			var msg = {
			   "event": "fastselectsimcard",
			   "handle": hdl,
			   "cardno":simno-1
			};
			console.log("快速切换卡:"+simno);
			/*
			如果失败， 在notify 消息中，会报错的
			*/
			websocket_send_msg(JSON.stringify(msg));		
		}
		else
		{
			console.log("型号不支持该功能");
		}
	}	
}

function CheckChinese(val){  
    
　　var reg = new RegExp("[\\u4E00-\\u9FFF]+","g");
　　if(reg.test(val)){  
       return true;        
　　}
	else
	{
		return false;
	}
  }

function toUnicode(str) {
	var temp,
	i = 0,
	r = '',
	len = str.length;
	for (; i < len; i++) {
		temp = str.charCodeAt(i).toString(16);		 
		while ( temp.length < 4 )
		temp = '0' + temp;	 
		r += temp;
	};
	 
	return r;
}
		  
function gsm_sendmsg(phone, val)
{
    var  msg=""; 
	var msgtype=0;
	if(hdl != -1)
	{
		if(ubox.lines[hdl].productid == 8)  //无线设备
		{
		   if(CheckChinese(val))
		   {
			  msg = toUnicode(val);
			  msgtype = 0;
			}
			else
			{
			   msg = val;
			   msgtype = 1;
			}
			console.log("往"+phone+"发送短信:"+msg);
			var msginfo = {
						"event": "sendmsg",
						"handle": hdl,
						"phone":phone,
						"mode": msgtype,
						"msg":msg
				};	
		//console.log("往"+phone+"发送短信:"+msg);
								/*
								如果失败， 在notify 消息中，会报错的
								*/
		websocket_send_msg(JSON.stringify(msginfo));
		}
	}
}

function gsm_queryshortmsgsize()
{
	if(hdl != -1)
	{
		if(ubox.lines[hdl].productid == 8)  //无线设备
		{
			var msg = {
			   "event": "msgsize",
			   "handle": hdl
			};
			console.log("查询短信数目");
			/*
			如果失败， 在notify 消息中，会报错的
			*/
			websocket_send_msg(JSON.stringify(msg));
		}
		else
		{
			console.log("型号不支持该功能");
		}
	}	
}

function ebox_query_tipwav()
{
		   	  var msg = {
				   "event": "GetVoiceFileNames"
	
			};

		  websocket_send_msg(JSON.stringify(msg));
}

function BtnPlayFile(filename)
{
    console.log("play filename:"+filename);
    var  path = ubox.workpath + "customvoices\\"+filename;
	console.log(path+",检查该文件是否存在！！！！");
	if(hdl != -1)
	{
		var msg = {
		   "event": "PlayFile",
		   "handle": hdl,
		   "ratio":  0,
		   "filename":path
		};
		console.log("开始放音...")
		/*
		如果失败， 在notify 消息中，会报错的
		*/
		websocket_send_msg(JSON.stringify(msg));
	}
}

function ebox_delete_Alltipwav()
{
		var msg = {
		   "event": "DeleteAllTipWav"
		};
			
		websocket_send_msg(JSON.stringify(msg));
		console.log("删除所有的提示音");
}
function  startAutoCallOut()
{
  // console.log($("#calloutphones").val());
   var content = $("#calloutphones").val().split("\n");
  // console.log("length:"+content.length);
   for(var i=0;i<content.length;i++)    //依次调用拨号接口，受环境限制，暂时不实现了，创建一个一秒的定时器，每呼叫一个号码，去查询电话状态，
   {
		console.log("phone:"+content[i]);
   }
}

function ubox_start_stream_record()
{
  if(hdl != -1)
	{
		var msg = {
		   "event": "StreamRecord",
		   "handle": hdl,
		   "codec":  CODER_PCM       //流式录音编码，建议用pcm格式
		};
		console.log("开始流式录音...")
		/*
		如果失败， 在notify 消息中，会报错的
		*/
		websocket_send_msg(JSON.stringify(msg));
	} 
}
function  stopAutoCallout()    //停止定时器，停止继续呼叫
{

}
function websocket_send_msg(msg)
{
	ws.send(msg);
}

$(function()
{
   if(window.WebSocket)
   {
	 console.log("浏览器支持WebSocket");
	 //http 
	
	 
	 var wsproto = (location.protocol === 'https:') ? "wss://" : "ws://";
	 //console.log("wsproto:"+wsproto);
	 //var wsport = (location.protocol === 'https:') ? ":7443" : ":5066";
	  //ws = new WebSocket(wsproto+'127.0.0.1:8054/ws?appid=595f23df&ts=1512041814&signa=IrrzsJeOFk1NGfJHW6SkHUoN9CU=');
	  ws = new WebSocket(wsproto+'127.0.0.1:8054/');
	 //https
	// ws = new WebSocket('wss://127.0.0.1:8054/wss');

	 // if (!window.console) { window.console = { log: function() {} } };

	  ws.onopen = function(ev)  { console.log("打开websocket:"+JSON.stringify(ev)) };
	  ws.onerror = function(ev) { console.log("websocket 错误:"+JSON.stringify(ev)); };
	  ws.onclose = function(ev) { console.log("websocket 已经关闭:"+JSON.stringify(ev));  ws = null;};
	  ws.onmessage = function(ev) {
		//console.log(ev);
		 if(typeof(ev.data)=="string"){
				 console.log(ev.data);
				 var msg = eval('(' + ev.data + ')');
				 // console.log("json:"+msg.event);
				 if(msg.event == "plugin")
				 {
					Ubox_Plug_In(msg.handle);
				 }
				 else if(msg.event == "hookoff")
				 {
					//  Ubox_hookoff(msg.handle);
					ubox.lines[msg.handle].state =  UBOX_STATE_HOOK_OFF
					 console.log("摘机，type:"+msg.type);
					if(msg.type == 6)
					{
						  ubox.lines[msg.handle].state = UBOX_STATE_HOOK_OFF;
						  console.log("软件摘机，反向");
					}
					else if(msg.type == 7)
					{
						ubox.lines[msg.handle].state = UBOX_STATE_HOOK_OFF;
						console.log("软件摘机，正向");
					}			
				 }
				 else if(msg.event == "hookup")
				 {
					Ubox_hookon(msg.handle);
				 }
				 else if(msg.event == "ring")
				 {
					ubox_Ring(msg.handle);
				 }
				 else if(msg.event == "hang")
				 {
					ubox_HangIng(msg.handle);
				 }
				 else if(msg.event == "callerId")
				 {
					Ubox_CallId(msg.handle, msg.callernumber);
				 }
				 else if(msg.event == "dtmfdown")
				 {
					ubox_DtmfKey(msg.handle, msg.dtmfkey);
				 }
				 else if(msg.event == "deviceAlarm")
				 {
					 ubox_DeviceAlarm(msg.handle, msg.type, msg.alarmParam);
				 }
				 else if(msg.event == "ringCancel")
				 {
					ubox_RingCancel(msg.handle);
				 }
				 else if(msg.event == "voltage")
				 {
					ubox_LineVoltage(msg.handle, msg.vol);
				 }
				 else if(msg.event == "deviceError")
				 {
					ubox_DeviceError(msg.handle);
				 }
				 else if(msg.event == "playEnd")
				 {
					ubox_PlayEnd(msg.handle);
				 }
				 else if(msg.event == "playError")
				 {
					console.log("放音失败， handle:"+msg.handle);
				 }
				 else if(msg.event == "busyTone")
				 {
					ubox_ToneBusy(msg.handle);
				 }
				 else if(msg.event == "CallOutFinish")
				 {
					console.log("软件拨号完成 handle:"+msg.handle);
				 }
				 else if(msg.event == "plugout")
				 {
					ubox_PlugOut(msg.handle);
				 }
				 else if(msg.event == "joined")
				 {
				   // console.log("test");
					ubox.workpath = decodeURIComponent(msg.path);
					console.log("connect websocket ok,work path:" +ubox.workpath);
					//var msg = '{"event": "getinfo"}';
					//websocket_send_msg(msg);
						//var msg = [];
						var msg = {
						   "event": "getinfo"
			
						};
						//var msg = '{"event": "RecordFile",';
						//msg +='"handle":'+hdl;
						websocket_send_msg(JSON.stringify(msg));
				 }
				 else if(msg.event == "left")
				 {
					console.log("断开websocket连接");
				 }
				 else if(msg.event=='polarity')
				 {
					console.log("检测到反极， 句柄：" +msg.handle +",val:"+msg.value); 
				 }
				 else if(msg.event == "calledanswer")
				 {
					//TODO
					console.log("已经应答"); 
					ubox_RecordFile() //开始录音
					window.cp.createCall().hide()
					let cpRecord = window.cp.cpRecord
					window.cp.createCall({phone:cpRecord.phone,callType:2,wdeviceDetails:cpRecord.wdeviceDetails,userInfo:cpRecord.userInfo}).show()
				 }
				 else if(msg.event == "getinfo")
				 {
					if(msg.channels.length > 0)
					{
					   console.log("device:"+JSON.stringify(msg.channels));
						for(var i=0;i<msg.channels.length;i++)
						{
							Ubox_Plug_In(msg.channels[i].handle);
							if(msg.channels[i].deviceError)
							  ubox_DeviceError(msg.channels[i].handle);
							if(msg.channels[i].deviceAlarm)
							{
    							 ubox_DeviceAlarm(msg.channels[i].handle, msg.channels[i].deviceAlarm,msg.channels[i].deviceAlarmParam);
							}
							
							if(msg.channels[i].devicePlayAlarm)
							{
    							 ubox_DeviceAlarm(msg.channels[i].handle, msg.channels[i].devicePlayAlarm,msg.channels[i].devicePlayAlarmParam);
							}
						}
					}
					else
					{
						console.log("没有检测到USB盒子设备插入");
					}
					{
						 console.log(" 查询提示音");
						  var data = {
							   "event": "GetVoiceFileNames"
				
						 };          
					   websocket_send_msg(JSON.stringify(data));
					}
				 }
				 else if(msg.event == "notify")
				 {
					console.log(msg.errorType+" handle:"+msg.handle +" "+Get_Error(msg.result));	
				 }
				 else if(msg.event == "answer")
				 {
				   if( msg.type == "GetDeviceVersionNum")
				   {
					  console.log("handle:"+msg.handle+" 硬件版本号："+msg.result);		
				   }
				   else if( msg.type == "GetUsernum")
				   {
					  console.log("handle:"+msg.handle+" 用户号:"+msg.result);		
				   }
				   else if( msg.type == "GetProductID")
				   {
					  if(msg.result == "1")
					  {
						 console.log("USB设备是FI3102A");
						ubox.lines[msg.handle].productid = 1;				 
					  }
					  else if(msg.result == "2")
					  {
						 console.log("USB设备是FI3002B");	
						 ubox.lines[msg.handle].productid = 2;					 
					  }		
					  else if(msg.result == "3")
					  {
						 console.log("USB设备是FI3001B");	
					   ubox.lines[msg.handle].productid = 3;						 
					  }		
					  else if(msg.result == "4")
					  {
						 console.log("USB设备是FI3101A");	
						 ubox.lines[msg.handle].productid = 4;						 
					  }
					  else if(msg.result == "8")
					  {
						 console.log("USB设备是无线设备");	
						 ubox.lines[msg.handle].productid = 8;	
						 $('#gsmmodsetting').show();						 
					  }	
					 else if(msg.result == "10")
					  {
						 console.log("USB设备是Fi3101B");	
						 ubox.lines[msg.handle].productid = 10;	
						 $('#3101Bmodsetting').show();				 
					  }				  
				   }			   
				 }
				 else if(msg.event == "systeminfo") 
				{ 
						for(var i=0;i<msg.macs.length;i++)
						{
								 console.log("mac name:"+msg.macs[i].macname);
								  console.log("mac des:"+msg.macs[i].macdes);
								  console.log("mac address:"+msg.macs[i].macaddr); 
								  for(var j=0;j<msg.macs[i].ips.length;j++)
									 console.log("ip address:"+msg.macs[i].ips[j].ipaddr); 
						}
						/*console.log("total memory:"+msg.memory.totalmem);
						console.log("free memory:"+msg.memory.freemem);
						console.log("virtual memory:"+msg.memory.virtualmem);
						console.log("disk SerialNo:"+msg.diskSerialNo);
						console.log("cpu SerialNo:"+msg.cpuSerialNo);
						for(var i=0;i<msg.disks.length;i++)
						{
								 console.log("disk id:"+msg.disks[i].id);
								  console.log("disk total:"+msg.disks[i].total);
								  console.log("disk free:"+msg.disks[i].free); 
			
						}*/
							   
				 }
				else if(msg.event == "short_msg_size")
				{
					 console.log("short message size:"+msg.number+" total memory size:"+msg.memory_size);
					 short_msg_number = msg.number;
					 if(short_msg_number >0)
					 {
						 gsm_sendcmd("AT+CMGR="+short_msg_number);
						 short_msg_number = short_msg_number -1;
					 }
				}
			   else if(msg.event == "short_msg")
			   {
				  // console.log("from:"+msg.from+" time:"+msg.time+" unicode:"+msg.result+" "+msg.divided + ",length:" +msg.result.length);
				   console.log("from:"+msg.from+" time:"+msg.time+" "+msg.divided );
					var result="";
					var pos = msg.result.length%4;
					console.log("pos:"+pos);
					for(var i=0;i<msg.result.length-pos;i++){
							  if(i%4 == 0)
							  {
								result += "\\u";
							  }
							result += msg.result[i+pos];
							
					}
					//if(CheckChinese(msg.result))
					{
						//document.write(result + "<br />")
						//console.log(msg.result);
						//console.log(result);
						result = unescape(result.replace(/\\u/g, "%u"));
						result = unescape(result.replace(/%u/g, ""));
						console.log(result);
					}  
					 if(short_msg_number >0)
					 {
						 gsm_sendcmd("AT+CMGR="+short_msg_number);
						 short_msg_number = short_msg_number -1;
					 }			
			   }
			   else if(msg.event == "StartUploadVoiceFileResult")
			   {
					console.log(ev.data); 
					if(msg.Code == 0)
					{
						EnabelUploadVoice = true;
						console.log("允许上传");
					}
					else
					{
						console.log("允许上传失败:"+msg.Code +",失败原因:"+ msg.Desc); 
					}

			   }
			   else if("UploadTipResult" == msg.event)
			   {
				   console.log(ev.data); 
					  var data = {
						   "event": "GetVoiceFileNames"
			
					};

				  websocket_send_msg(JSON.stringify(data));
			   }
				else if(msg.event == "signalsize")
				{
					console.log("信号大小:"+msg.size);
				}
				else if(msg.event == "GetVoiceFileNames")
				{
					console.log("GetVoiceFileNames");
					$("#newbtarea").empty();
					ubox.tipfiles = msg.FileNames;
					for(var i=0;i<msg.FileNames.length;i++)
					{
						console.log("filename:"+msg.FileNames[i].filename);
						console.log("filesize:"+msg.FileNames[i].filesize);
						var btnvl = $(sprintf("<input type='button' class='ws-btn'  id='Btnid%d' value='%s' onclick=\"BtnPlayFile('%s')\">&nbsp;&nbsp;&nbsp;&nbsp;" , i, msg.FileNames[i].filename,msg.FileNames[i].filename));	
					   $("#newbtarea").append(btnvl);

					}
				}
				 else
				 {
					console.log(ev.data);
				 }
		 }
		 else    //流式录音流
		 {
			var reader = new FileReader();
			reader.onload = function(ev){
			if(ev.target.readyState == FileReader.DONE){
					var data = new Uint8Array(ev.target.result);
					//console.log("recv  stream  ");
                    //ws.binaryType = "arraybuffer"; 
					// ws.send(data.buffer);  
				 }
			}
			reader.readAsArrayBuffer(ev.data);		
		 }
	  };

   }
   else
   {
		console.log(" 浏览器不支持WebSocket.");
   }
   
     $("#fileImport").click(function () {
	        console.log("fileImport click");
            $("#uboxfiles").click();
        });
		
	 $('#autoanswerck').click(function(){
	     if ($(this).prop("checked"))
          {
		      console.log("打开了自动应答模式" );	
              if(ubox.tipfiles.length == 0)
              {
			    
			     console.log("没有提示语音，不能播放语音" ); 
              }
              else
			  {
			     
			      console.log("电话呼入，自动摘机，播放第一个语音" ); 
			  }
              ubox.callinMode = 1;			  
          }
          else
          {
		       ubox.callinMode = 0;
		       console.log("关闭自动应答模式" );	
          }
	});
});

function fileImport() {
            //获取读取我文件的File对象
			console.log("开始导入");
            var selectedFile = document.getElementById('uboxfiles').files[0];
            var name = selectedFile.name;//读取选中文件的文件名
            var size = selectedFile.size;//读取选中文件的大小
            console.log("文件名:"+name+"大小:"+size);
			var msg = {
			   "Event": "StartUploadVoiceFile",
			   "FileName":name,
			   "FileSize":size
			};
			console.log("开始上传");
		
			websocket_send_msg(JSON.stringify(msg));
			setTimeout(function()
			{
				if(EnabelUploadVoice)
				{
					var reader = new FileReader();//这是核心,读取操作就是由它完成.
					reader.readAsArrayBuffer(selectedFile);//读取文件的内容,也可以读取文件的URL
					reader.onload = function () {
						//当读取完成后回调这个函数,然后此时文件的内容存储到了result中,直接操作即可
					//	console.log(this.result);
						   var data = new Uint8Array(this.result);
						   ws.binaryType = "arraybuffer"; 
						   ws.send(data.buffer); 
						//ws.binaryType = "arraybuffer"; 
					   // ws.send(this.result);
					}
				}
				else
				{
					console.log("系统不允许上传语音文件")
				}
			}, 1200)
 }

 export {
	ubox_dialnum,
	ubox_hook_off,
	ubox_hook_on,
	ubox_reject_on,
	ubox_RecordFile,//开始录音
}